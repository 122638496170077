import React, { useEffect, useContext } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import $ from "jquery";

import { Footer, Home, DetailPage, PageNotFound, LaunchingEvent, Submit, PageQuestionnaire, Result, ViewAll } from "./components";
import { definePageHash } from "./common/index";
import { languageOptions } from "../src/languages";
import { LanguageContext } from "../src/contexts/Languages";


function App() {
  const languageContext = useContext(LanguageContext);

  useEffect(() => {
    const { hash, search } = window.location;

    if (search) {
      const langParam = search.includes('?lang=vn') ? 'vn' : 'en' ;
      const selectedLanguage = languageOptions.find(
        (item) => item.id === langParam
      );
  
      // set selected language by calling context method
      languageContext.setLanguage(selectedLanguage);
  
      // set languageTo localStorage
  
      localStorage.setItem("innovation_lang", JSON.stringify(selectedLanguage));
    }

    if (hash !== "" && definePageHash.includes(hash)) {
      $("#mainMenu li a").removeClass("active");
      $(`a[href="${hash}"]`).addClass("active");

      $(window).on('load', function() {
        $("html, body").animate(
          {
            scrollTop: $(hash).offset().top - 65,
          },
          100
        );
      });
    }
  }, []);

  return (
    <div className="App">
      <div className="page-wrapper">
        <BrowserRouter>
          <Switch>
            <Route exact path={`/`} component={Home} />
            {/* <Route exact path={`/${languageContext.language.id}`} component={Home} /> */}
            <Route path="/inspire-insight" component={DetailPage} />
            <Route path="/acceleration" component={DetailPage} />
            <Route path="/idea-crowd-sourcing" component={DetailPage} />
            <Route path="/challenge" component={DetailPage} />
            <Route path="/trainning" component={DetailPage} />
            <Route path="/advisory-go-to-market" component={DetailPage} />
            <Route path="/advisory-strategy" component={DetailPage} />
            <Route path="/co-creation" component={DetailPage} />
            <Route path="/launching-event" component={LaunchingEvent} />
            <Route path="/assessment" component={Submit} />
            <Route path="/questionnaire" component={PageQuestionnaire} />
            <Route path="/result" component={Result} />
            <Route path="/view_all" component={ViewAll} />
            <Route path="/ResidentialRealEstate" component={DetailPage} />
            <Route path="/*" component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </div>

      <Footer />
    </div>
  );
}

export default App;
