import React from "react";
import { Container, Image } from "react-bootstrap";
import { LINE_IMAGE } from "../../common/image";
import { languageOptions } from "../../languages";
import { TranslateLangSimple } from "./../../contexts/Languages";

const URL_PAGE_CHECK = "/assessment"
const pageName = "INNOVATION READINESS ASSESSMENT"
const color = "white"

const existLang = localStorage.getItem('innovation_lang') ? JSON.parse(localStorage.getItem('innovation_lang')) : languageOptions[0];

const improvementQuotient = existLang.id == 'en' ? {
  quotientTitle: "Needs Improvement",
  quotientMessage: [
    "You've taken the first step towards innovation. You'll be amazed at what new opportunities active innovation management can open up. Go for it!",
    "Innovation is the starting point to make the opportunities of future changes useable for the company and to successfully overcome challenges. You will receive a Global CIO Survey 2020 from KPMG that points up the way to success."
  ],
  quotientColor: "dark-pink"
} : {
    quotientTitle: "Cần cải thiện",
    quotientMessage: [
      "Bạn đã thực hiện những bước đầu tiên để đổi mới. Cơ hội phát triển đến từ mô hình đổi mới sáng tạo trong doanh nghiệp là không giới hạn.",
      "Đây là điểm khởi đầu để tạo ra cơ hội thay đổi trong tương lai và giúp cho doanh nghiệp vượt qua những thử thách, bài toán trong hiện tại để thành công. KPMG cũng sẽ gửi đến quý doanh nghiệp Báo cáo Khảo sát CIO toàn cầu 2020 để cung cấp một cái nhìn rõ hơn về cơ hội đổi mới trên nền tảng công nghệ."
    ],
    quotientColor: "dark-pink"
  };

const averageQuotient = existLang.id == 'en' ? {
  quotientTitle: "Average",
  quotientMessage: [
    "You have already laid down the important principles for innovation management. Generate enthusiasm for innovation in your environment as well. Grasp the potential, step by step.",
    "Innovation is the starting point to make the opportunities of future changes useable for the company and to successfully overcome challenges. You will receive a Global CIO Survey 2020 from KPMG that points up the way to success."
  ],
  quotientColor: "light-orange"
} : {
    quotientTitle: "Trung bình",
    quotientMessage: [
      "Bạn đã đặt ra các nguyên tắc quan trọng để quản lý đổi mới sáng tạo trong doanh nghiệp. Hãy từng bước thực hiện theo kế hoạch và tiếp tục xây dựng một môi trường làm việc sáng tạo.",
      "Đây là điểm khởi đầu để tạo ra cơ hội thay đổi trong tương lai và giúp cho doanh nghiệp vượt qua những thử thách, bài toán trong hiện tại để thành công. KPMG cũng sẽ gửi đến quý doanh nghiệp Báo cáo Khảo sát CIO toàn cầu 2020 để cung cấp một cái nhìn rõ hơn về cơ hội đổi mới trên nền tảng công nghệ."
    ],
    quotientColor: "light-orange"
  };

const goodQuotient = existLang.id == 'en' ? {
  quotientTitle: "Good",
  quotientMessage: [
    "It's not going to be easy for anyone to steal a march on you. Become an ambassador for innovation and put your organization in pole position. Go for it!",
    "Innovation is the starting point to make the opportunities of future changes useable for the company and to successfully overcome challenges. You will receive a Global CIO Survey 2020 from KPMG that points up the way to success."
  ],
  quotientColor: "blue"
} : {
    quotientTitle: "Tốt",
    quotientMessage: [
      "Đổi mới sáng tạo đang trở thành giá trị đột phá cho doanh nghiệp của bạn. Hãy từng bước thực hiện theo kế hoạch và trở thành hình mẫu ứng dụng đổi mới để phát triển nhanh và bền vững.",
      "Đây là điểm khởi đầu để tạo ra cơ hội thay đổi trong tương lai và giúp cho doanh nghiệp vượt qua những thử thách, bài toán trong hiện tại để thành công. KPMG cũng sẽ gửi đến quý doanh nghiệp Báo cáo Khảo sát CIO toàn cầu 2020 để cung cấp một cái nhìn rõ hơn về cơ hội đổi mới trên nền tảng công nghệ."
    ],
    quotientColor: "blue"
  };

const excellentQuotient = existLang.id == 'en' ? {
  quotientTitle: "Excellent",
  quotientMessage: [
    "Very good! You know what it's all about. There's only a few points that are separating you from the best of the best. You just need to find the right places to tweak!",
    "Innovation is the starting point to make the opportunities of future changes useable for the company and to successfully overcome challenges. You will receive a Global CIO Survey 2020 from KPMG that points up the way to success."
  ],
  quotientColor: "green"
} : {
    quotientTitle: "Xuất sắc",
    quotientMessage: [
      "Rất tốt! Bạn biết mình phải làm gì trong hành trình đổi mới sáng tạo. Chỉ còn một số điểm đang ngăn cách bạn với những người giỏi nhất. Bạn chỉ cần tìm đúng nơi để điều chỉnh!",
      "Đây là điểm khởi đầu để tạo ra cơ hội thay đổi trong tương lai và giúp cho doanh nghiệp vượt qua những thử thách, bài toán trong hiện tại để thành công. KPMG cũng sẽ gửi đến quý doanh nghiệp Báo cáo Khảo sát CIO toàn cầu 2020 để cung cấp một cái nhìn rõ hơn về cơ hội đổi mới trên nền tảng công nghệ."
    ],
    quotientColor: "green"
  };

const resultGroup266 = require('../../assets/image/result-group-266.png')
const emailContact = require('../../assets/image/email-contact.png')
const innovationWebsite = require('../../assets/image/innovation-website.png')

export class Result extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }

  onLoad() {
    if (window.performance.navigation.type == 2) {
      localStorage.removeItem("assessment_sum_point");
      window.location.href = URL_PAGE_CHECK;
    }

    var pointTotal = parseInt(localStorage.getItem("assessment_sum_point"));
    if (isNaN(pointTotal)) {
      window.location.href = URL_PAGE_CHECK;
    } else {
      this.state.loading = false;
    }

    var innovationQuotient = {
      pointTotal: null,
      quotientTitle: null,
      quotientMessage: [],
      quotientColor: null
    }

    if (pointTotal <= 11) {
      innovationQuotient = improvementQuotient;
    }
    if (pointTotal >= 12 && pointTotal <= 22) {
      innovationQuotient = averageQuotient;
    }
    if (pointTotal >= 23 && pointTotal <= 28) {
      innovationQuotient = goodQuotient;
    }
    if (pointTotal >= 29) {
      innovationQuotient = excellentQuotient;
    }

    innovationQuotient.pointTotal = pointTotal;
    return innovationQuotient;
  }

  render() {
    var dt = this.onLoad();
    return (
      <>
        <header className="header-front-page detail result-header">
          <nav role="navigation" id="header">
            <Container className="height65">
              <div className="content-header">
                <div className="navbar-header logo mr-5">
                  <Image className="mr-4" src={require("../../assets/image/kpmg-logo.svg")} alt="logo" />
                  <Image src={require("../../assets/image/tma-logo.svg")} alt="logo" />
                </div>
              </div>
            </Container>
            <div className="container h-100">
              <div className="cls-banner-content">
                <div className="title-banner">INNOVATION READINESS<br />ASSESSMENT</div>
              </div>
            </div>
          </nav>
        </header>
        <div
          style={{ backgroundColor: color }}
          className="flex justify-content-center page-detail"
        >
          {this.state.loading
            ? (
              <div className="questionnaire-loading flex">
                <div className="icon-loading"></div>
              </div>
            )
            : (
              <div className="check-result">
                <Container>
                  <div className="first-section">
                    <div className="result-box">
                      <div className="box-header align-items-center text-white">
                        <div className="text-center">{TranslateLangSimple(["resultPage", "pageTitle"])}</div>
                      </div>
                      <div className="box-content">
                        <div className="result-content">
                          <div className="row">
                            <div className="col-md-4">
                            </div>
                            <div className="col-md-7 mb-3">
                              <div className="block">
                                <div className="description thanks-title">
                                  {TranslateLangSimple(["resultPage", "thankful1"])}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-4 mt-3">
                              <div className="box-wrapper text-center mb-lg-0">
                                <div className="text-point">
                                  <p className={`result-point ${dt.quotientColor}`}>{dt.pointTotal}</p>
                                </div>
                                <Image src={resultGroup266} alt="Your Point" />
                              </div>
                            </div>
                            <div className="col-md-7">
                              <div className="block">
                                <div className="title">
                                  {dt.quotientTitle}<br />
                                  <Image src={LINE_IMAGE} alt="Line Icon" className="line-img" />
                                </div>
                                <div className="description">
                                  <p>{dt.quotientMessage[0]}</p>
                                  <p>{dt.quotientMessage[1]}</p>
                                  <p>{TranslateLangSimple(["resultPage", "thankful2"])}</p>
                                  <a
                                    target="self"
                                    href={require("../../assets/files/TheCIOSurveyReport2020.pdf")}
                                    download="TheCIOSurveyReport2020.pdf"
                                    className="btn btn-primary submit-btn-gradient mt-3"
                                    title={TranslateLangSimple(["resultPage", "download"])}
                                  >
                                    {TranslateLangSimple(["resultPage", "download"])}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="second-section">
                    <div className="text-center">
                      
                    </div>
                    <div className="title text-center">{TranslateLangSimple(["resultPage", "sponsored"])}</div>
                    <div className="title text-center">{TranslateLangSimple(["resultPage", "contact"])}</div>
                    <div className="row padding-991">
                      <div className="col-md-6">
                        <div className="box-wrapper text-center mt-4 mb-lg-0">
                          <a href="mailto:contact@innovation.com.vn">
                            <Image src={emailContact} alt="Contact Email" />
                          </a>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="box-wrapper text-center mt-4 mb-lg-0">
                          <a href="https://innovation.com.vn" className="mr-3">
                            <Image src={innovationWebsite} alt="Innovation Website" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </Container>
              </div>
            )}
        </div>
      </>
    )
  }
};
