import React from "react";

import { Image, Container } from "react-bootstrap";
import { TranslateLanguage } from "../../contexts/Languages";

export const CoCreation = () => {
  const itemService = [
    {
      name: "Product Planning",
      image: require("../../assets/image/product-planning.svg"),
    },
    {
      name: "Solution Consulting",
      image: require("../../assets/image/solution-consulting.svg"),
    },
    {
      name: "Prototyping",
      image: require("../../assets/image/prototyping.svg"),
    },
    {
      name: "Proof of Concept",
      image: require("../../assets/image/proof-of-concept.svg"),
    },
    {
      name: "R&D",
      image: require("../../assets/image/r&d.svg"),
    },
    {
      name: "UX/UI Design",
      image: require("../../assets/image/ux-ui-design.svg"),
    },
    {
      name: "Product Development",
      image: require("../../assets/image/product-development.svg"),
    },
    {
      name: "Product Testing",
      image: require("../../assets/image/product-testing.svg"),
    },
    {
      name: "Porting & Migration",
      image: require("../../assets/image/porting-migration.svg"),
    },
    {
      name: "IT Managed Services",
      image: require("../../assets/image/it-managed-service.svg"),
    },
    {
      name: "",
      image: require("../../assets/image/it-managed-service.svg"),
    },
    {
      name: "",
      image: require("../../assets/image/it-managed-service.svg"),
    },
  ];
  const itemSolutions = [
    {
      name: "Go Digital",
      image: require("../../assets/image/go-digital.svg"),
    },
    {
      name: "Go Online",
      image: require("../../assets/image/go-online.svg"),
    },
    {
      name: "Go Mobile",
      image: require("../../assets/image/go-mobile.svg"),
    },
    {
      name: "Go Cloud",
      image: require("../../assets/image/go-cloud.svg"),
    },
    {
      name: "Go Automation",
      image: require("../../assets/image/go-automation.svg"),
    },
    {
      name: "Go Integrated",
      image: require("../../assets/image/go-intergate.svg"),
    },
    {
      name: "Go Smart",
      image: require("../../assets/image/go-smart.svg"),
    },
    {
      name: "Go Interactive",
      image: require("../../assets/image/go-interactive.svg"),
    },
    {
      name: "Go Secure",
      image: require("../../assets/image/go-secure.svg"),
    },
  ];
  return (
    <div className="co-creation acceleration-idea d-flex align-items-center w-100">
      <Container className="flex co-creation">
        <div className="text-center mbt40">
          {TranslateLanguage('coCreationPage', 'End-to-end solution from Ideas to Product')}

        </div>
        <div class="row justify-content-center">
          <div class="flex innovation-panership-content">
            <div class="title-block first-section">{TranslateLanguage('coCreationPage', 'Services')}</div>
            <Image src="/static/media/line.cbcfa932.svg" />
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap justify-content-center services-grid mbt40">
          {itemService.map((item) => {
            if (!item.name) {
              return <div className="item"></div>;
            }
            return (
              <div className="item">
                <div className="content">
                  <Image
                    src={item.image}
                    alt="acceleration-idea"
                    className="img-fluid image"
                  />
                  <div className="name">{TranslateLanguage('coCreationPage', item.name)}</div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mbt40 cls-border"></div>
        <div class="row justify-content-center">
          <div class="flex innovation-panership-content">
        <div class="title-block first-section">{TranslateLanguage('coCreationPage', 'Solutions')}</div>
            <Image src="/static/media/line.cbcfa932.svg" />
          </div>
        </div>
        <div className="d-flex flex-row flex-wrap justify-content-center services-grid">
          {itemSolutions.map((item) => {
            if (!item.name) {
              return <div className="item"></div>;
            }
            return (
              <div className="item">
                <div className="content">
                  <Image
                    src={item.image}
                    alt="acceleration-idea"
                    className="img-fluid image"
                  />
                  <div className="name">{TranslateLanguage('coCreationPage', item.name)}</div>
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};
