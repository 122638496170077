import React, { useContext } from "react";

import { Image, Container, Col, Row } from "react-bootstrap";
import { TranslateLanguage, LanguageContext } from "../../contexts/Languages";

const data = {
  description: `KPMG will contribute to designing and running an open innovation challenge centered around a corporate's business objective. 
  This program aims to align your organization's internal knowledge and resources with KPMG services and alliance partners to pursue your innovation-driven development. 
  `,
  input: [
    {
      id: 1,
      icon: require("../../assets/image/icons/group-people.svg"),
      title: "Leading Corporate",
      des: ["Growth objectives", "Industry experts and talent"],
    },
    {
      id: 2,
      icon: require("../../assets/image/icons/group-people.svg"),
      title: "KPMG - Innovation Service Provider",
      des: [
        "Professional innovation services",
        "Alliance experts",
        "Resources from startup ecosystem",
      ],
    },
  ],
  acceleration: [
    [
      {
        id: 1,
        title: "Ideation",
        des:
          "By most efficiency methodology, we work together for seeing the root of problem & innovative solutions",
      },
      {
        id: 2,
        title: "Strategy",
        des:
          "If you want to win a big game, a clear path to success need to be identified",
      },
    ],
    [
      {
        id: 3,
        title: "Prioritization",
        des:
          "We prioritize the innovation that minimizes disruption risk and maximizes growth.",
      },
      {
        id: 4,
        title: "Case for Change",
        des: `We predict risks and mitigation plan for them. That will guarantee for our expectation`,
      },
    ],
  ],
  prototype: [
    {
      id: 1,
      icon: require("../../assets/image/icons/proof-of-concept.svg"),
      title: "Proof of Concept",
      des: [
        `Within 2-3 weeks, your idea can be touch-and-feel. In digital era, speed is number one competitive advantage`,
      ],
    },
    {
      id: 2,
      icon: require("../../assets/image/icons/user-testing.svg"),
      title: "User Testing",
      des: [
        `We can test out the market & get feedbacks after we have POC. This is the great input value for Go-to-market decision`,
      ],
    },
  ],
};

export const AccelerationChallenge = () => {
  const languageContext = useContext(LanguageContext);
  const { language } = languageContext;
  const { description, input, acceleration, prototype } = data;

  const showBoxContent = (data) => {
    return (
      data &&
      data.map((item, index) => {
        const showLine = index < input.length - 1;
        return (
          <React.Fragment key={item.id}>
            <div className="text-center block">
              <Image src={item.icon} alt={item.title} className="img-fluid" />
              <div className="title mt-3 mb-2">{TranslateLanguage('accelerationChallengePage',item.title)}</div>
              <div className="description">
                {item.des &&
                  item.des.map((el, idx) => (
                    <p className="mb-0" key={idx}>
                      {TranslateLanguage('accelerationChallengePage',el)}
                    </p>
                  ))}
              </div>
            </div>
            {showLine && <div className="border-bottom-block" />}
          </React.Fragment>
        );
      })
    );
  };

  return (
    <div className="acceleration-idea d-flex align-items-center">
      <Container>
        <p className="description text-center">{TranslateLanguage('accelerationChallengePage', description, 'description')}</p>

        <Row>
          <Col lg={3} md={4}>
            <div className={`gradient-box ${language.id}`} >
              <div className="box-header d-flex align-items-center text-white">
                <Image src={require("../../assets/image/icons/input.svg")} />
                <div>{TranslateLanguage('accelerationChallengePage', 'Input')}</div>
              </div>
              <div className={`box-content ${language.id}`}>{showBoxContent(input)}</div>
            </div>
          </Col>

          <Col lg={6} md={8}>
            <div className={`gradient-box ${language.id}`} >
              <div className="box-header d-flex align-items-center text-white">
                <Image
                  src={require("../../assets/image/icons/high-speed.svg")}
                />
                <div>{TranslateLanguage('accelerationChallengePage', 'Acceleration')}</div>
              </div>

              <div className={`box-content acceleration ${language.id}`}>
                <div className="acceleration-content">
                  <Image
                    className="d-md-none d-block m-auto"
                    src={require(`../../assets/image/icons/accelerator-${language.id}.svg`)}
                  />

                  <div>
                    {acceleration.map((el, idx) => {
                      return (
                        <Row key={idx}>
                          {el.map((item, index) => (
                            <Col
                              key={item.id}
                              md={6}
                              className={`mb-4 ${
                                index % 2 !== 0 ? "text-md-right" : ""
                                }`}
                            >
                              <div className="light-blue-block">
                                <div>
                                  <div className="title">{TranslateLanguage('accelerationChallengePage', item.title)}</div>
                                  <div className="description">{TranslateLanguage('accelerationChallengePage', item.des)}</div>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      );
                    })}
                  </div>
                  <Image
                    src={require(`../../assets/image/icons/accelerator-${language.id}.svg`)}
                    className="d-none d-md-block accelerator-decor"
                  />
                </div>
              </div>
            </div>
          </Col>

          <Col lg={3} md={12}>
            <div className={`gradient-box ${language.id}`} >
              <div className="box-header d-flex align-items-center text-white">
                <Image
                  src={require("../../assets/image/icons/prototype.svg")}
                />
                <div>{TranslateLanguage('accelerationChallengePage', 'Prototype')}</div>
              </div>

              <div className={`box-content ${language.id}`}>{showBoxContent(prototype)}</div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
