import React, { useContext, useEffect } from "react";
import { Image, Row, Col, Container } from "react-bootstrap";
import $ from "jquery";

import Header from "../Header";
import { TranslateLang, LanguageContext } from "./../../contexts/Languages";

const NewsEN = () => {
  return (
    <>
      <div className="title mb-2 mb-md-4 mt-5 mt-md-0">
        <span className="d-block">TMA Solutions and KPMG</span>
        <span className="d-block">
          Launching Innovation as a Service for the first time in Vietnam
        </span>
      </div>

      <div className="content">
        <p>
          We are in the post COVID period when the market is changing and
          companies can only survive and succeed by innovating. Understanding
          the enterprises’ challenges, the KPMG & TMA Alliance has launched
          Innovation as a Service, which helps companies to solve their
          challenges by applying the latest technologies to create innovative
          products and services, and to be more competitive.
        </p>

        <p>
          TMA already has experience in global innovation with leading tech
          companies. This has enabled TMA to learn not only the latest
          technologies, especially industry 4.0 technologies, but also the
          innovation process from ideas to prototype to product development and
          deployment. We want to apply these technologies and best practices to
          support Vietnamese companies in innovation by applying the latest tech
          and methods to build creative products and solutions to increase the
          competitive advantages for customers in various industries.
        </p>

        <p>
          Meanwhile, KPMG Vietnam over the past 26 years has developed into the
          market leader in the field of business & technology consulting.
          Through combining multiple approaches with deep and practical industry
          knowledge, KPMG has completed over 20 technology transformation
          projects within the last 36 months.
        </p>
        <Row className="mb-4 mb-md-5 mt-4 mt-md-5">
          <Col md={6}>
            <Image
              src={TranslateLang("launchingEvent.chairman1Img")}
              className="img-fluid mb-3 mb-md-0"
            />
          </Col>
          <Col md={6}>
            <Image
              src={TranslateLang("launchingEvent.chairman2Img")}
              className="img-fluid"
            />
          </Col>
        </Row>
        <p>
          Innovation as a Service operates based on co-creation among 3 teams
          (Client - KPMG - TMA) that work together to analyze challenges and
          propose innovative solutions to develop prototypes and products. This
          model also helps to build an innovation team for clients to make
          innovation become part of their corporate culture and practice.
        </p>

        <p>
          To support the innovation process from ideas to product, TMA and KPMG
          have developed a Hackathon platform. This platform enables companies
          to gather ideas from their community then launch and operate
          challenges and hackathons on-line with minimum effort and cost. This
          platform also cultivates an innovation ecosystem, a network of
          innovative insights and ideas.
        </p>

        <p>
          Both TMA and KPMG share the vision of bringing global innovation
          practices to Vietnam. By combining KPMG consulting and domain
          knowledge with TMA’s technology, we have a very strong capability to
          offer Innovation as a Service in Vietnam enterprises.
        </p>
      </div>
    </>
  );
};

const NewsVN = () => {
  return (
    <>
      <div className="title mb-2 mb-md-4 mt-5 mt-md-0">
        <span className="d-block">Innovation as a service:</span>
        <span className="d-block">
          Đưa kinh nghiệm đổi mới sáng tạo của thế giới vào Việt Nam
        </span>
      </div>

      <p>
        Nền kinh tế Việt Nam đang chuyển dịch từ phát triển theo chiều rộng sang
        chiều sâu, nên động lực phát triển không còn là tài nguyên, đất đai, giá
        nhân công thấp mà là công nghệ và sáng tạo.
      </p>

      <p>
        Hiện nay Covid-19 đang thay đổi mạnh mẽ nền kinh tế Việt Nam và thế
        giới. Tất cả các doanh nghiệp đều phải thay đổi, thích nghi để có thể
        tiếp tục phát triển. Liên tục đổi mới sáng tạo và ứng dụng công nghệ mới
        sẽ giúp các doanh nghiệp nâng cao hiệu quả, năng lực cạnh tranh và tiết
        kiệm thời gian, nhân lực. Tuy nhiên quá trình đổi mới sáng tạo chưa thực
        sự phổ biến ở Việt Nam nên các doanh nghiệp còn lúng túng. Innovation as
        a service – dịch vụ hợp tác chiến lược giữa TMA và KPMG sẽ giúp các
        doanh nghiệp giải quyết thách thức này bằng cách áp dụng các quy trình
        và phương pháp đổi mới sáng tạo từ các nước hàng đầu, kết hợp với các
        công nghệ mới nhất (trí tuệ nhân tạo, dữ liệu lớn, Blockchain, IoT, điện
        toán đám mây…).{" "}
      </p>

      <Row className="mb-4 mb-md-5 mt-4 mt-md-5">
        <Col md={6}>
          <Image
            src={TranslateLang("launchingEvent.chairman1Img")}
            className="img-fluid mb-3 mb-md-0"
          />
        </Col>
        <Col md={6}>
          <Image
            src={TranslateLang("launchingEvent.chairman2Img")}
            className="img-fluid"
          />
        </Col>
      </Row>

      <p>
        Dịch vụ đổi mới sáng tạo (Innovation as a service) là mô hình hợp tác 3
        bên (Khách hàng – KPMG – TMA), cả 3 sẽ hợp tác chặt chẽ nhằm phân tích
        các thử thách của thị trường, đề ra các sản phẩm và giải pháp mới. Khách
        hàng không chỉ nhận được sản phẩm đột phá mà còn học hỏi được phương
        pháp và quy trình đổi mới sáng tạo để tiếp tục áp dụng vào tất cả các bộ
        phận, đưa đổi mới sáng tạo trở thành một phần của văn hóa doanh nghiệp,
        tạo động lực phát triển mới.{" "}
      </p>

      <p>
        Như một phần của Innovation as a service, nền tảng Hackathon Việt Nam
        được ra mắt để hỗ trợ cho quá trình đổi mới của các doanh nghiệp. Nền
        tảng này hoạt động theo mô hình tận dụng những sáng kiến của cộng đồng
        và các chuyên gia để giải quyết những vấn đề do doanh nghiệp đưa ra. Đây
        là sân chơi cho những người đam mê sáng tạo, nơi mọi người có thể thực
        hành, học hỏi, tham gia thử thách và nhận các giải thưởng.{" "}
      </p>

      <p>
        TMA là công ty công nghệ hàng đầu Việt Nam với 23 năm kinh nghiệm trong
        lĩnh vực phát triển phần mềm, nhận sự tín nhiệm của hàng trăm khách hàng
        là các tập đoàn lớn đến từ 27 quốc gia trên toàn thế giới. Đội ngũ hơn
        2.500 kỹ sư của TMA đang triển khai các giải pháp công nghệ mới cho
        khách hàng trong nhiều lĩnh vực như: Tài chính ngân hàng, y tế, giáo
        dục, giao thông vận tải, thương mại điện tử…
      </p>

      <p>
        KPMG bắt đầu hoạt động tại Việt Nam từ năm 1994 với 3 văn phòng ở Hà
        Nội, TP.HCM và Đà Nẵng. 26 năm hoạt động tại Việt Nam đã giúp KPMG có
        kinh nghiệm phong phú về thị trường và trở thành công ty tư vấn lớn nhất
        Việt Nam. KPMG cũng đã thực hiện thành công hơn 20 dự án công nghệ trong
        vòng 36 tháng qua.{" "}
      </p>

      <p>
        Cả TMA và KPMG đều có chung tầm nhìn đưa kinh nghiệm đổi mới sáng tạo
        của thế giới đến các doanh nghiệp Việt Nam. Bằng cách kết hợp kinh
        nghiệm tư vấn của KPMG và công nghệ của TMA, Innovation as a service có
        thể giúp các doanh nghiệp xây dựng các sản phẩm và giải pháp sáng tạo để
        tăng lợi thế cạnh tranh trong các ngành khác nhau.{" "}
      </p>
    </>
  );
};

export const LaunchingEvent = () => {
  const languageContext = useContext(LanguageContext);
  const { language } = languageContext;

  const metaTitle = TranslateLang("common.launchingEventMetaTitle");
  const metaDescription = TranslateLang("common.launchingEventMetaDescription");

  useEffect(() => {
    document.title = metaTitle;
    $("meta[name=description]").attr("content", metaDescription);
  }, [language]);

  return (
    <div className="launching-event">
      <Header isHome={true} news={true}>
        <div className="content mt-4 mt-md-0">
          <iframe
            width="100%"
            height="550"
            src="https://www.youtube.com/embed/LAc4J7zkjxM"
            frameBorder="0"
            allow="picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Header>

      <Container>
        <Row>
          <div className="content page-content col">
            {language.id === "en" ? <NewsEN /> : <NewsVN />}
            <p className="flex mb-5 mt-4">
              <Image
                src={require(`../../assets/image/acceleration-idea-${language.id}.svg`)}
                alt="acceleration-idea"
                className="img-fluid mt-4 d-none d-md-block"
              />
              <Image
                src={require(`../../assets/image/acceleration-idea-mobile-${language.id}.svg`)}
                alt="acceleration-idea"
                className="img-fluid mt-4 d-md-none"
              />
            </p>
            <div className="see-more">
              <h6>{TranslateLang("launchingEvent.exploreMore")}</h6>
              <div>
                Innovation as a Service:{" "}
                <a href="https://www.innovation.com.vn/" target="_blank">
                  https://www.innovation.com.vn/
                </a>
              </div>
              <div>
                TMA Solutions:{" "}
                <a href="https://www.tmasolutions.com/" target="_blank">
                  https://www.tmasolutions.com/
                </a>
              </div>
              <div>
                KPMG:{" "}
                <a href="https://home.kpmg/" target="_blank">
                  https://home.kpmg/
                </a>
              </div>
              <div>
                Vietnam Hackathon:{" "}
                <a href="https://hackathon.vn/" target="_blank">
                  https://hackathon.vn/
                </a>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};
