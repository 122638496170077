import React from "react";

import { Image, Container } from "react-bootstrap";
import { TranslateLanguage } from "../../contexts/Languages";

export const Trainning = () => {
  const liSosution = [
    'KPMG Next',
    'Innovation Culture',
    'Customer and Operation (SCM)',
    'Financial Management',
    'People & Change',
    'Risk management'
  ]

  const liCuture = [
    'Innovation and Strategic Management',
    'Design Thinking',
    'Project Management',
  ]

  const liStrategy = [
    'Digital Transformation',
    'Technology Strategy',
    'Emerging Technology',
  ]

  return (
    <div className="acceleration-idea d-flex align-items-center trainning">
      <Container className="flex">
        <p className="description mb-3 text-center text-ceneter">
          {TranslateLanguage('tranningPage', 'Our training program consists of various courses designed to help organizations gravitate towards being masters of innovation implementation, and help them stand at the forefront of innovation in their respective fields. The courses aim to help individuals to independently lead and drive business transformation initiatives.', 'contentPage')}
        </p>
        <div className="row block-content">
          <div className="item">
            <div className="content-item d-flex align-items-center">
              <div className="d-flex align-items-center">
                <Image src={require("../../assets/image/trainning-1.svg")} />
                <div className="pl5">
                  {TranslateLanguage('tranningPage', 'Strategic planning & business management skills')}
                </div>
              </div>
            </div>
            <div className="content-below">
              <div className="title-item">BUILD INNOVATIVE SOLUTIONS</div>
              <ul>
                {liSosution.map(item => <li> {TranslateLanguage('tranningPage', item)}</li>)}
              </ul>
            </div>
          </div>
          <div className="item">
            <div className="content-item d-flex align-items-center">
              <div className="d-flex align-items-center">
                <Image src={require("../../assets/image/trainning-2.svg")} />
                <div className="pl5">  {TranslateLanguage('tranningPage', 'Core Innovative Skills')}</div>
              </div>
            </div>
            <div className="content-below">
              <div className="title-item">BUILD AN INNOVATIVE CULTURE</div>
              <ul>
                {liCuture.map(item => <li> {TranslateLanguage('tranningPage', item)}</li>)}
              </ul>
            </div>
          </div>
          <div className="item">
            <div className="content-item d-flex align-items-center">
              <div className="d-flex align-items-center">
                <Image src={require("../../assets/image/trainning-3.svg")} />
                <div className="pl5"> {TranslateLanguage('tranningPage', 'Technical knowledge and sense')}</div>
              </div>
            </div>
            <div className="content-below">
              <div className="title-item">BUILD A FINANCIAL STRATEGY</div>
              <div className="d-flex">
                <ul>
                  {liStrategy.map(item => <li> {TranslateLanguage('tranningPage', item)}</li>)}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
