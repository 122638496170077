import React from "react";
import { Container, Image, Pagination, Table } from "react-bootstrap";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const fields = [
	"user_id",
	"full_name",
	"job_title",
	"company_name",
	"email",
	"mobile",
	"industry",
	"company_size",
	"number_employee",
	"interested_topic",
	"QUESTION_1",
	"QUESTION_2",
	"QUESTION_3",
	"QUESTION_4",
	"QUESTION_5",
	"QUESTION_6",
	"QUESTION_7",
	"QUESTION_8",
	"QUESTION_9",
	"QUESTION_10",
	"QUESTION_11",
	"QUESTION_12",
	"QUESTION_13",
	"QUESTION_14",
	"QUESTION_15",
	"QUESTION_16",
	"QUESTION_17",
	"QUESTION_18",
	"QUESTION_19",
	"QUESTION_20"
];

export class ViewAll extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageRows: [],
			pageLength: 1,
			pageCurrent: 1
		};
		this.data = {
			rows: null,
			countPerPage: 25
		};
	}
	updateTable(pageNum) {
		this.state.pageLength = Math.ceil(this.data.rows.length / this.data.countPerPage);
		this.state.pageCurrent = pageNum;
		this.state.pageRows = this.data.rows.slice((pageNum - 1) * this.data.countPerPage, pageNum * this.data.countPerPage);
		this.setState(this.state);
	}
	componentWillMount() {
		fetch("https://innovation.com.vn:8123/api/view_all")
			.then(response => response.json())
			.then(rows => {
				this.data.rows = rows.sort(function (a, b) { return a.user_id - b.user_id });;
				this.updateTable(this.state.pageCurrent);
			})
			.catch((error) => {
				console.error(error);
			});
	}
	downloadExcelFile() {
		const workSheet = XLSX.utils.json_to_sheet(this.data.rows);
		const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] };
		const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' });
		const data = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8" });
		FileSaver.saveAs(data, "innovation-data.xlsx");
	}
	render() {
		return (
			<div className="flex justify-content-center page-detail view-all">
				<header className="header-front-page w-100">
					<Container className="height65 pt-4">
						<Image className="mr-4" src={require("../../assets/image/kpmg-logo.svg")} alt="logo" />
						<Image src={require("../../assets/image/tma-logo.svg")} alt="logo" />
					</Container>
					<div className="container h-100">
						<div className="cls-banner-content pt-0">
							<div className="title-banner">View All</div>
						</div>
					</div>
				</header>
				<div className="flex-grow-1 flex w-100 p-4">
					{
						this.data.rows &&
						<>
							<div className="flex flex-row pb-4">
								<Pagination className="mb-0 mr-4">
									<Pagination.First onClick={() => this.updateTable(1)} disabled={this.state.pageCurrent == 1} />
									<Pagination.Prev onClick={() => this.updateTable(this.state.pageCurrent - 1)} disabled={this.state.pageCurrent == 1} />
									{
										(this.state.pageCurrent != 1) && <Pagination.Ellipsis disabled />
									}
									{
										[-2, -1, 0, 1, 2].filter(i => this.state.pageCurrent + i >= 1 && this.state.pageCurrent + i <= this.state.pageLength).map(i => (
											<Pagination.Item key={i} onClick={() => this.updateTable(this.state.pageCurrent + i)} active={i == 0}>{this.state.pageCurrent + i}</Pagination.Item>
										))
									}
									{
										(this.state.pageCurrent != this.state.pageLength) && <Pagination.Ellipsis disabled />
									}
									<Pagination.Next onClick={() => this.updateTable(this.state.pageCurrent + 1)} disabled={this.state.pageCurrent == this.state.pageLength} />
									<Pagination.Last onClick={() => this.updateTable(this.state.pageLength)} disabled={this.state.pageCurrent == this.state.pageLength} />
								</Pagination>
								<button onClick={() => this.downloadExcelFile()} className="btn btn-primary">Download Excel File</button>
							</div>
							<div className="table-container flex-grow-1 w-100">
								<div className="table">
									<Table striped bordered hover>
										<thead>
											<tr>
												{
													fields.map((field, i) => (<th key={i}>{field}</th>))
												}
											</tr>
										</thead>
										<tbody>
											{
												this.state.pageRows.map((row, i) => (
													<tr key={i}>
														{
															Object.keys(row).map(key => (
																<td key={key}>{row[key]}</td>
															))
														}
													</tr>
												))
											}
										</tbody>
									</Table>
								</div>
							</div>
						</>
					}
				</div>
			</div>
		);
	}
};
