import React, { useState, createContext, useContext } from "react";

import { languageOptions, dictionaryList } from "../languages";
import _ from 'lodash'

const existLang = localStorage.getItem('innovation_lang') ? JSON.parse(localStorage.getItem('innovation_lang')) : languageOptions[0];


// create the language context with default selected language
export const LanguageContext = createContext({
  language: existLang,
  dictionary: dictionaryList[existLang.id],
});

// it provides the language context to app
export function LanguageProvider(props) {
  const languageContext = useContext(LanguageContext);
  const [language, setLanguage] = useState(languageContext.language);
  const [dictionary, setDictionary] = useState(languageContext.dictionary);

  const provider = {
    language,
    dictionary,
    setLanguage: (selectedLanguage) => {
      setLanguage(selectedLanguage);
      setDictionary(dictionaryList[selectedLanguage.id]);
    },
  };

  return (
    <LanguageContext.Provider value={provider}>
      {props.children}
    </LanguageContext.Provider>
  );
}

//access deep value of object
const access_deep_value = (obj, path) => {
  let newPath = path.split(".");
  for (
    let i = 0, newpath = path.split("."), len = newpath.length;
    i < len;
    i++
  ) {
    obj = obj[newPath[i]];
  }
  return obj;
};

// get text according to id & current language
export const TranslateLang = (text) => {
  const languageContext = useContext(LanguageContext);

  const label = access_deep_value(languageContext.dictionary, text);
  return label ? label : '';
}


export const TranslateLanguage = (page, text, key = '') => {
  const languageContext = useContext(LanguageContext);
  let label = '';
  const { language } = languageContext;
  if (language.id === 'en') {
    label = text
  } else {
    label = _.get(languageContext.dictionary[page], key ? key : text, '')
  }
  return label ? label : '';
}

export const TranslateLangSimple = (arr_key) => {
  let tmp_obj = dictionaryList[existLang.id]
  for (let i = 0; i < arr_key.length; i++) {
    if (tmp_obj[arr_key[i]]) {
      tmp_obj = tmp_obj[arr_key[i]]
    }
  }
  return tmp_obj
}

export const TranslateLangSimpleWithContext = (arr_key) => {
  const languageContext = useContext(LanguageContext);
  let tmp_obj = languageContext.dictionary;
  for (let i = 0; i < arr_key.length; i++) {
    if (tmp_obj[arr_key[i]]) {
      tmp_obj = tmp_obj[arr_key[i]]
    }
  }
  return tmp_obj
}
