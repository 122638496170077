import React from "react";
import { Image } from "react-bootstrap";
import { TranslateLang } from "./../contexts/Languages";

export const ContactForm = () => {
  const contactInfo = [
    {
      id: 1,
      icon: require("../assets/image/icons/location-icon.svg"),
      content: [
        TranslateLang('homePage.getInTouch.company'),
        TranslateLang('homePage.getInTouch.location')
      ]
    },
    {
      id: 2,
      icon: require("../assets/image/icons/phone-icon.svg"),
      content: [TranslateLang('homePage.getInTouch.tel')],
    },
    {
      id: 3,
      icon: require("../assets/image/icons/fax-icon.svg"),
      content: ["+84 (28) 3821 9267"],
    },
    {
      id: 4,
      icon: require("../assets/image/icons/email-icon.svg"),
      content: ["contact@innovation.com.vn"],
    },
  ];

  return (
    <section className="contact-us" id="contact-us">
      <div className="container">
        <div className="flex mb-5">
          <div className="title-block mb-1">{TranslateLang('homePage.getInTouch.title')}</div>
          <Image
            className="image1"
            src={require("../assets/image/line.svg")}
            alt="line-icon"
          />
        </div>

        <div className="row">
          <div className="col-md-6 d-flex justify-content-center mb-5 mb-lg-0">
            <div className="contact-wrapper mr-md-5">
              <div className="contact-border">
                <ul>
                  {contactInfo.map((item) => (
                    <li className="d-flex align-items-center" key={item.id}>
                      <Image src={item.icon} fluid/>
                      <div>
                        {item.content.map((el, idx) => (
                          <span className="d-block word-break" key={idx}>{el}</span>
                        ))}
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <p className="text-nowrap-md mb-2 mb-lg-5">
              {TranslateLang('homePage.getInTouch.textDes')}
            </p>
            <form
              method="POST"
              id="contact_form"
              action="contact.php"
              aria-label="Contact form"
            >
              <div className="form-group">
                <input
                  required
                  type="text"
                  name="name"
                  className="form-control"
                  aria-describedby="nameInput"
                  placeholder={`${TranslateLang('common.name')}*`}
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="email"
                  name="email"
                  title="Your email is wrong format"
                  className="form-control"
                  aria-describedby="emailInput"
                  placeholder={`${TranslateLang('common.email')}*`}
                />
              </div>
              <div className="form-group">
                <input
                  required
                  type="tel"
                  pattern="\d*"
                  minLength="10"
                  title="Your phone number is wrong format"
                  name="phone"
                  className="form-control"
                  aria-describedby="phoneInput"
                  placeholder={`${TranslateLang('common.phone')}*`}
                />
              </div>
              <div className="form-group">
                <textarea
                  required
                  className="form-control"
                  name="message"
                  aria-describedby="message"
                  rows="1"
                  placeholder={`${TranslateLang('common.messagePlaceholder')}*`}
                />
              </div>
              <div
                id="g-recaptcha"
                className="g-recaptcha mt-4"
                data-sitekey="6LfinOcUAAAAAASeB-ozIxDzPSnI7Ew-R-LMusxn"
                data-callback="verifyCaptcha"
              ></div>
              <div id="g-recaptcha-error"></div>

              <div
                id="form-error"
                className="alert alert-danger mb-0 mt-4"
                role="alert"
              ></div>
              <button
                type="submit"
                className="btn btn-primary btn-gradient mt-3"
              >
                {TranslateLang('common.submit')}
              </button>
            </form>
          </div>
        </div>
      </div>

      <div
        className="modal"
        id="popup-show-noti"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="popup-show-noti"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body text-center d-flex flex-column align-items-center justify-content-center">
              <div>
                <Image
                  src={require("../assets/image/check.svg")}
                  className="img-fluid mb-1"
                  al="check icon"
                />
                <h3 className="title-block mt-3">{TranslateLang('common.thankYou')}</h3>
                <p className="message mb-3">
                  {TranslateLang('common.submitSuccess')}
                  <br />
                  {TranslateLang('common.promiseSendEmail')}
                </p>
                <button className="btn btn-primary btn-gradient scrollTop mt-4">
                  {TranslateLang('common.returnHome')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
