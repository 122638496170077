import React from "react";
import { Image, Container } from "react-bootstrap";
import { TranslateLangSimpleWithContext } from "./../../contexts/Languages";

export const ResidentialRealEstate = () => {
    return (
        <div className="detail-event-page">
            <div className="acceleration-idea d-flex align-items-center block-2 block-6">
                <Container className="flex">
                    <div className="row">
                        <div className="content-item d-flex align-items-center">
                            <div className="pl5">
                                {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block6", "title"])}
                            </div>
                        </div>
                        <div className="content-below">
                            <div className="paragraph">
                                <div className="center-paragraph">
                                    {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block6", "text1"])}
                                </div>
                            </div>
                            <div className="g-button">
                                <div className="b6-button left">
                                    {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block6", "button1"])}
                                </div>
                                <div className="b6-button left">
                                    {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block6", "button2"])}
                                </div>
                            </div>
                            <div className="doted-line"></div>
                        </div>
                    </div>
                </Container>
            </div>
            {/* <div className="acceleration-idea d-flex align-items-center block-2 block-7">
                <Container className="flex">
                    <div className="row">
                        <div className="content-item d-flex align-items-center">
                            <div className="pl5">
                                {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block7", "title"])}
                            </div>
                        </div>
                        <div className="content-below">
                            <div className="g-speafer">
                                <div className="speaker left">
                                    <div className="avatar avatar-1">
                                    </div>
                                    <div className="name">
                                        Nguyễn Trần Trung Quân
                                    </div>
                                    <div className="role">
                                        Head of Digital Innovation
                                    </div>
                                    <div className="logo">
                                        <Image src={require('../../assets/image/icons/spe-logo-1.png')} className="img-fluid image" />
                                    </div>
                                </div>
                                <div className="speaker right">
                                    <div className="avatar avatar-2">
                                    </div>
                                    <div className="name">
                                        phạm toàn thắng
                                    </div>
                                    <div className="role">
                                        CEO
                                    </div>
                                    <div className="logo">
                                        <Image src={require('../../assets/image/icons/spe-logo-2.png')} className="img-fluid image" />
                                    </div>
                                </div>
                            </div>
                            <div className="doted-line"></div>
                        </div>
                    </div>
                </Container>
            </div> */}
            <div className="acceleration-idea d-flex align-items-center block-2 padding-bottom-none">
                <Container className="flex">
                    <div className="row">
                        <div className="content-item d-flex align-items-center">
                            <div className="pl5 b2-title">
                                {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block2", "title"])}
                            </div>
                        </div>
                        <div className="content-below">
                            <ul className="agenda-list">
                                <li className="agen-item">
                                    {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block2", "text1"])}
                                </li>
                                <li className="agen-item">
                                    {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block2", "text2"])}
                                </li>
                                <li className="agen-item">
                                    {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block2", "text3"])}
                                </li>
                            </ul>
                            <div className="doted-line"></div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="acceleration-idea d-flex align-items-center block-2 block-8">
                <Container className="flex">
                    <div className="row">
                        <div className="content-item d-flex align-items-center">
                            <div className="pl5 b2-title">
                                {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block8", "title"])}
                            </div>
                        </div>
                        <div className="content-below">
                            <ul className="list-block-8">
                                <li className="list-block-8-item m-b-24">
                                    {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block8", "text1"])}
                                </li>
                                <li className="list-block-8-item">
                                    {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block8", "text2"])}
                                </li>
                            </ul>
                            <div className="doted-line"></div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="acceleration-idea align-items-center block-3">
                <Container className="flex">
                    <div className="row contact">
                        <div className="contact-title">
                            {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block3", "text1"])}
                        </div>
                        <div className="contact-content">
                            <div className="col-md-6 contact-col">
                                <div className="contact-wrapper">
                                    <ul>
                                        <li className="d-flex align-items-center header-text">
                                            <div>
                                                <span className="" >{TranslateLangSimpleWithContext(["ResidentialRealEstate", "block3", "contact2"])}</span>
                                            </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <Image src={require("../../assets/image/icons/email-icon.svg")} fluid />
                                            <div>
                                                <span className="text" >oanhbui@kpmg.com.vn</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 contact-col">
                                <div className=" contact-wrapper">
                                    <ul>
                                        <li className="d-flex align-items-center header-text">
                                            <div>
                                                <span className="" >{TranslateLangSimpleWithContext(["ResidentialRealEstate", "block3", "contact1"])}</span>
                                            </div>
                                        </li>
                                        <li className="d-flex align-items-center">
                                            <Image src={require("../../assets/image/icons/email-icon.svg")} fluid />
                                            <div>
                                                <span className="text" >trucnguyen1@kpmg.com.vn</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="doted-line"></div>
                    </div>
                </Container>
            </div>
            <div className="acceleration-idea align-items-center block-5">
                <Container className="flex">
                    <div className="row contact">
                        <div className="contact-title">
                            {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block5", "text1"])}
                        </div>
                        <div className="text-bold">
                            {TranslateLangSimpleWithContext(["ResidentialRealEstate", "block5", "text2"])}
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};
