import  React from 'react';

import { TranslateLang } from "./../contexts/Languages";

export const Footer = () => {
  return (
    <footer className="d-flex align-items-center justify-content-center text-center text-md-left p-2 p-md-0">
      <div>2020 {TranslateLang('common.footer')}</div>
    </footer>
  )
}