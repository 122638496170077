import React, { useContext } from "react";
import { Image, Row, Col, Button, Carousel } from "react-bootstrap";

import { ContactForm } from "../ContactForm";
import Header from "../Header";
import { LINE_IMAGE } from "../../common/image";
import { TranslateLang, LanguageContext } from "./../../contexts/Languages";

const whyInnovation = [
  {
    id: 1,
    listContent: ["content1", "content2", "content3", "content4", "content5"],
  },
  {
    id: 2,
    listContent: ["content1", "content2"],
    contactUs: `homePage.whyInnovation.block2.contactUs`,
  },
  {
    id: 3,
    listContent: [
      "content1",
      "content2",
      "content3",
      "content4",
      "content5",
      "content6",
    ],
    textDes: `homePage.whyInnovation.block3.textDes`,
  },
];

const whyChooseUs = [
  {
    id: 1,
    icon: require("../../assets/image/icons/why-choose-us-icon-1.svg"),
    mobileIcon: require("../../assets/image/icons/why-choose-us-icon-mobile-1.svg"),
  },
  {
    id: 2,
    icon: require("../../assets/image/icons/why-choose-us-icon-2.svg"),
    mobileIcon: require("../../assets/image/icons/why-choose-us-icon-mobile-2.svg"),
  },
  {
    id: 3,
    icon: require("../../assets/image/icons/why-choose-us-icon-3.svg"),
    mobileIcon: require("../../assets/image/icons/why-choose-us-icon-mobile-3.svg"),
  },
  {
    id: 4,
    icon: require("../../assets/image/icons/why-choose-us-icon-4.svg"),
    mobileIcon: require("../../assets/image/icons/why-choose-us-icon-mobile-4.svg"),
  },
];

export const Home = () => {
  const languageContext = useContext(LanguageContext);
  return (
    <>
      <Header isHome={true} isDetailPage={false}>
        <Carousel className="home-slider"
          controls={false}
        >
          <Carousel.Item interval={1000}>
            <Row>
              <div className="banner2-layer col-md-6 col-lg">
                <div className="img-wrapper">
                  <Image src={require('../../assets/image/icons/icon_home_banner_2.png')} className="img-fluid image" />
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center col-md-6 col-lg">
                <div className="text-center text-md-left pl-0 pl-md-3">
                  <div className="intro-content mt-3 mt-md-0 mb-4">
                    <div className="bold banner-2-title">
                      {TranslateLang("homePage.homeBanner2.title")}
                    </div>
                    <div className="fs24 mb-2 mb-md-3">
                      {TranslateLang("homePage.homeBanner2.message1")}
                    </div>
                  </div>
                  <a href="/assessment" role="button" type="button" className="launching-banner-2-button btn btn-primary" target="_blank">
                    {TranslateLang("homePage.homeBanner2.button")}
                  </a>
                </div>
              </div>
            </Row>
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item interval={1000}>
            <Row>
              <div className="banner-layer col-md-6 col-lg">
                <div className="video-wrapper">
                  <iframe
                    width="100%"
                    height="335"
                    src="https://www.youtube.com/embed/LAc4J7zkjxM"
                    frameBorder="0"
                    allow="picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-center col-md-6 col-lg">
                <div className="text-center text-md-left pl-0 pl-md-3">
                  <div className="intro-content mt-3 mt-md-0 mb-4">
                    <h6 className="fs24 mb-2 mb-md-3">
                      {TranslateLang("common.cooperate")}
                    </h6>
                    <div className="bold">
                      {TranslateLang("homePage.homeBanner.message1")}
                    </div>
                    <div className="bold">
                      {TranslateLang("homePage.homeBanner.message2")}
                    </div>
                  </div>
                  <a href="/launching-event" role="button" type="button" className="launching-button btn btn-primary" target="_blank">
                    {TranslateLang("common.viewLaunchingEvent")}
                  </a>
                </div>
              </div>
            </Row>
            <Carousel.Caption>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>

      </Header>
      <section className="innovation-ecosystem" id="innovation-ecosystem">
        <div className="container">
          <div className="row justify-content-center ">
            <div className="flex innovation-panership-content">
              <div className="title-block first-section">
                {TranslateLang("homePage.innovationEcosystem.title")}
              </div>
              <Image src={LINE_IMAGE} />
            </div>
          </div>

          <Row className="block-content align-items-center border-bottom-block">
            <Col md={5}>
              <Image
                src={TranslateLang("homePage.innovationEcosystem.organizeImg")}
                fluid
              />
            </Col>
            <Col className="content padding-block-1" md={7}>
              <div>
                {TranslateLang("homePage.innovationEcosystem.content.content1")}
              </div>
              <div>
                {TranslateLang("homePage.innovationEcosystem.content.content2")}
              </div>
              <div>
                {TranslateLang("homePage.innovationEcosystem.content.content3")}
              </div>
            </Col>
          </Row>

          <div className="container pat50 corporate">
            <div className="row justify-content-center ">
              <div className="flex innovation-panership-content">
                <div className="title-block">
                  {TranslateLang("homePage.whyInnovation.title")}
                </div>
                <div className="short-description">
                  {TranslateLang("homePage.whyInnovation.des")}
                </div>
              </div>
            </div>
            <div className="row block-content border-bottom-block">
              {whyInnovation &&
                whyInnovation.map((item, i) => (
                  <div className="item" key={item.id}>
                    <div className="content-item flex flex-row">
                      <div>
                        <Image
                          src={require("../../assets/image/question-mark.svg")}
                        />
                      </div>
                      <div className="pl5">
                        {TranslateLang(
                          `homePage.whyInnovation.block${i + 1}.title`
                        )}
                      </div>
                    </div>
                    <div className="content-below">
                      {item.listContent && (
                        <ul>
                          {item.listContent.map((content, idx) => (
                            <li key={idx}>
                              {TranslateLang(
                                `homePage.whyInnovation.block${i + 1
                                }.${content}`
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                      {item.contactUs && (
                        <a href="#contact-us" className="contact">
                          {TranslateLang(item.contactUs)}
                        </a>
                      )}
                      {item.textDes && (
                        <div className="text-des mt-4">
                          {TranslateLang(item.textDes)}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="container pat50 corporate our-approach-block">
            <div className="row justify-content-center ">
              <div className="flex innovation-panership-content">
                <div className="title-block">
                  {TranslateLang(`homePage.theInnovationLifecycle.title`)}
                </div>
                <div className="short-description">
                  {TranslateLang(`homePage.theInnovationLifecycle.textDes`)}
                </div>
              </div>
            </div>
            <div className="row block-content pb-0 d-flex justify-content-center">
              <Image
                className="img-fluid image"
                src={TranslateLang(
                  `homePage.theInnovationLifecycle.lifeCycleImg`
                )}
              />
              <Image
                className="img-fluid image-mobile"
                src={TranslateLang(
                  `homePage.theInnovationLifecycle.lifeCycleImgMobile`
                )}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="innovation-as-service" id="innovation-as-service">
        <div className="container">
          <div className="row justify-content-center">
            <div className="flex innovation-panership-content">
              <div className="title-block first-section">
                {TranslateLang(`homePage.innovationAsAService.title`)}: <br />
                {TranslateLang(`homePage.innovationAsAService.ourApproach`)}
              </div>
              <Image src={LINE_IMAGE} />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6 col-lg-4 col-12 justify-content-center block">
              <div className="inside">
                <div className="title-block">
                  <Image
                    className="image"
                    src={require("../../assets/image/inspire.svg")}
                  />
                  <div className="cls-title">
                    {TranslateLang(
                      `homePage.innovationAsAService.inspireInsight.title`
                    )}
                  </div>
                </div>
                <div className="content">
                  <div className="mb-4">
                    <div className="title">
                      {TranslateLang(
                        `homePage.innovationAsAService.inspireInsight.blockContent1.title`
                      )}
                    </div>
                    <div>
                      {TranslateLang(
                        `homePage.innovationAsAService.inspireInsight.blockContent1.textDes`
                      )}
                    </div>
                  </div>

                  <div>
                    <div className="title">
                      {TranslateLang(
                        `homePage.innovationAsAService.inspireInsight.blockContent2.title`
                      )}
                    </div>
                    <div>
                      {TranslateLang(
                        `homePage.innovationAsAService.inspireInsight.blockContent2.textDes`
                      )}
                    </div>
                  </div>
                  <div className="read-more">
                    <a href="/inspire-insight">
                      {TranslateLang(`common.readMore`)}
                    </a>
                  </div>
                </div>
              </div>

              <div className="inside">
                <div className="title-block">
                  <Image
                    className="image"
                    src={require("../../assets/image/aceleration.svg")}
                  />
                  <div className="cls-title">
                    {TranslateLang(
                      `homePage.innovationAsAService.acceleration.title`
                    )}
                  </div>
                </div>
                <div className="content">
                  <div className="mb-4">
                    <div className="title">
                      {TranslateLang(
                        `homePage.innovationAsAService.acceleration.blockContent1.title`
                      )}
                    </div>
                    <div>
                      {TranslateLang(
                        `homePage.innovationAsAService.acceleration.blockContent1.textDes`
                      )}
                    </div>
                    <div className="read-more">
                      <a href="/idea-crowd-sourcing">
                        {TranslateLang(`common.readMore`)}
                      </a>
                    </div>
                  </div>

                  <div>
                    <div className="title">
                      {TranslateLang(
                        `homePage.innovationAsAService.acceleration.blockContent2.title`
                      )}
                    </div>
                    <div>
                      {TranslateLang(
                        `homePage.innovationAsAService.acceleration.blockContent2.textDes`
                      )}
                    </div>
                  </div>

                  <div className="read-more">
                    <a href="/challenge">{TranslateLang(`common.readMore`)}</a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-12 justify-content-center block">
              <div className="inside">
                <div className="title-block">
                  <Image
                    className="image"
                    src={require("../../assets/image/trainning.svg")}
                  />
                  <div className="cls-title">
                    {TranslateLang(
                      `homePage.innovationAsAService.training.title`
                    )}
                  </div>
                </div>
                <div className="content">
                  <div>
                    {TranslateLang(
                      `homePage.innovationAsAService.training.content`
                    )}
                  </div>

                  <div className="read-more">
                    <a href="/trainning">{TranslateLang(`common.readMore`)}</a>
                  </div>
                </div>
              </div>
              <div className="inside">
                <div className="title-block">
                  <Image
                    className="image"
                    src={require("../../assets/image/advisory.svg")}
                  />
                  <div className="cls-title">
                    {TranslateLang(
                      `homePage.innovationAsAService.advisory.title`
                    )}
                  </div>
                </div>
                <div className="content">
                  <div className="mb-4">
                    <div className="title">
                      {TranslateLang(
                        `homePage.innovationAsAService.advisory.blockContent1.title`
                      )}
                    </div>
                    <div>
                      {TranslateLang(
                        `homePage.innovationAsAService.advisory.blockContent1.textDes`
                      )}
                    </div>
                    <div className="read-more">
                      <a href="/advisory-strategy">
                        {TranslateLang(`common.readMore`)}
                      </a>
                    </div>
                  </div>
                  <div className="title">
                    {TranslateLang(
                      `homePage.innovationAsAService.advisory.blockContent2.title`
                    )}
                  </div>
                  <div>
                    {TranslateLang(
                      `homePage.innovationAsAService.advisory.blockContent2.textDes`
                    )}
                  </div>
                  <div className="read-more">
                    <a href="/advisory-go-to-market">
                      {TranslateLang(`common.readMore`)}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-4 col-12 justify-content-center block">
              <div className="inside">
                <div className="title-block">
                  <Image
                    className="image"
                    src={require("../../assets/image/co-creation.svg")}
                  />
                  <div className="cls-title">
                    {TranslateLang(
                      `homePage.innovationAsAService.coCreation.title`
                    )}
                  </div>
                </div>
                <div className="content custom-line-height">
                  <div className="title"></div>
                  <ul className="p-0">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: TranslateLang(
                          `homePage.innovationAsAService.coCreation.content`
                        ),
                      }}
                    />
                  </ul>

                  <div className="read-more">
                    <a href="/co-creation">
                      {TranslateLang(`common.readMore`)}
                    </a>
                  </div>
                </div>
              </div>
              <div className="inside gradient">
                <div className="item">
                  <div className="content flex flex-row p-4 mt-0">
                    <div>
                      <Image
                        src={require("../../assets/image/icons/gradient-question-mark.svg")}
                      />
                    </div>
                    <div className="pl5">
                      {TranslateLang(
                        `homePage.innovationAsAService.download.textDes`
                      )}
                    </div>
                  </div>
                  <div className="content-below text-center px-2">
                    <a
                      target="self"
                      href={require("../../assets/files/Innovation-Service-Packages.pdf")}
                      download="Innovation-Service-Packages.pdf"
                      className="text-white download-file"
                      title={TranslateLang('common.downloadTitle')}
                    >
                      {TranslateLang(
                        `homePage.innovationAsAService.download.linkLabel`
                      )}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className={`why-choose-us text-white ${languageContext.language.id}`}
        id="why-choose-us"
      >
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-4 col-xl-5">
              {/* <h4 className="d-block d-lg-none section-title">
                {TranslateLang(`homePage.whyChooseUs.title`)}
              </h4> */}
            </div>
            <div className="col-lg-8 col-xl-7 d-flex justify-content-center flex-column">
              <div className="mobile-margin">
                {whyChooseUs.map((item, i) => (
                  <div className="block" key={item.id}>
                    <Image src={item.icon} className="d-none d-md-block" />
                    <Image src={item.mobileIcon} className="d-md-none" />
                    <div className="text-des text-center text-md-left">
                      {TranslateLang(`homePage.whyChooseUs.content${i + 1}`)}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <ContactForm />
    </>
  );
};
