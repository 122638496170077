import React, { useContext } from "react";

import { Image, Container } from "react-bootstrap";
import { TranslateLang, TranslateLanguage, LanguageContext } from '../../contexts/Languages'

export const AccelerationIdea = () => {
  const languageContext = useContext(LanguageContext);
  const { language } = languageContext;
  return (
    <div className="acceleration-idea d-flex align-items-center">
      <Container className="flex">
        <p className="description mb-3 text-center">
          {TranslateLanguage('accelerationIdeaPage', 'The challenge poses a problem to a diverse group of people, allowing them to address an urgent and relevant theme. Initial proposals will be selected and incubated to high quality ideas. This opens up a company-wide innovation process to employees, and fosters cross-functional collaboration.')}
        </p>
        <Image
          src={require(`../../assets/image/acceleration-idea-${language.id}.svg`)}
          alt="acceleration-idea"
          className="img-fluid mt-4 d-none d-md-block"
        />
        <Image
          src={require(`../../assets/image/acceleration-idea-mobile-${language.id}.svg`)}
          alt="acceleration-idea"
          className="img-fluid mt-4 d-md-none"
        />
      </Container>
    </div>
  );
};
