import React, { useContext } from "react";
import { Image, Container } from "react-bootstrap";
import $ from "jquery";

import { TranslateLanguage, LanguageContext, TranslateLangSimple, TranslateLangSimpleWithContext } from "./../contexts/Languages";
import LanguageSelector from "./LanguageSelector";

const menu = [
  {
    id: 1,
    href: "#home",
    label: "Home",
    active: true,
  },
  {
    id: 2,
    href: "#innovation-ecosystem",
    label: "Ecosystem",
  },
  {
    id: 3,
    href: "#innovation-as-service",
    label: "Innovation as a service",
    isSubmenu: true,
  },
  {
    id: 4,
    href: "assessment",
    label: "Online Assessment",
  },
  {
    id: 5,
    href: "#why-choose-us",
    label: "Community",
  },
  {
    id: 6,
    href: "#contact-us",
    label: "Contact Us",
  },
];

const subMenu = [
  {
    id: 1,
    href: "/inspire-insight",
    label: "Inspire & Insight",
  },
  {
    id: 2,
    href: "/idea-crowd-sourcing",
    label: "Acceleration / Idea Crowd-Sourcing",
  },
  {
    id: 3,
    href: "/challenge",
    label: "Acceleration / Challenge",
  },
  {
    id: 4,
    href: "/trainning",
    label: "Training",
  },
  {
    id: 5,
    href: "/co-creation",
    label: "Co-Creation",
  },
  {
    id: 6,
    href: "/advisory-strategy",
    label: "Advisory / Strategy",
  },
  {
    id: 7,
    href: "/advisory-go-to-market",
    label: "Advisory / Go to Market",
  },
];

const HamburgerButton = () => {
  const hamburgerClick = () => {
    $("#hamburger").toggleClass("active");
  };

  return (
    <div className="navbar-toggler collapsed">
      <button
        className=""
        type="button"
        data-toggle="collapse"
        data-target="#mainMenu"
        aria-controls="mainMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
        id="hamburger"
        onClick={hamburgerClick}
      >
        <div>
          <span></span>
        </div>
      </button>
    </div>
  );
};

const Logo = () => (
  <div className="navbar-header logo mr-5">
    <div className="navbar-brand">
      <a className="mr-4 non-active" href="https://innovation.com.vn/">
        <Image src={require("../assets/image/kpmg-logo.svg")} alt="logo" />
      </a>
      <a className="non-active" href="https://innovation.com.vn/">
        <Image src={require("../assets/image/tma-logo.svg")} alt="logo" />
      </a>
      <a className="mr-4 image-active" href="https://innovation.com.vn/">
        <Image src={require("../assets/image/kpmg2-logo.svg")} alt="logo" />
      </a>
      <a className="image-active" href="https://innovation.com.vn/">
        <Image src={require("../assets/image/tma2-logo.svg")} alt="logo" />
      </a>
    </div>
  </div>
);

const RenderMenu = ({ menu, isDetailPage, news, isFixedLink }) => {
  const languageContext = useContext(LanguageContext);

  // console.log('languageContext ', languageContext)

  const handleNavigate = (e, item) => {
    e.preventDefault();
    if (isDetailPage || news || item.id == 4) {
      if (isFixedLink) {
        window.location.assign(`https://innovation.com.vn/${item.href}`);
      }
      else {
        window.location.assign(`/${item.href}`);
      }
    }
  };

  const showDropDownMenu = (item) => {
    if (item.isSubmenu) {
      $(".dropdown").toggleClass("expanded");
    }
  };

  return menu.map((item) => {
    return (
      <li key={item.id} className={item.isSubmenu ? "dropdown" : ""}>
        <div>
          {isFixedLink ? (<a
            href={`https://innovation.com.vn/${item.href}${languageContext.lang ? `/${languageContext.lang.id}` : ''}`}
            className={item.active ? "active" : ""}
          >
            {TranslateLanguage(`menu`, item.label)}
          </a>) : (<a
            href={`${item.href}${languageContext.lang ? `/${languageContext.lang.id}` : ''}`}
            className={item.active ? "active" : ""}
            onClick={(e) => handleNavigate(e, item)}
          >
            {TranslateLanguage(`menu`, item.label)}
          </a>)}
          {item.isSubmenu && (
            <>
              <span className="ml-1 dropdown-icon d-none d-md-inline">
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.99999 3.59998L5.99999 6.59998L8.99999 3.59998L10.2 4.19998L5.99999 8.39997L1.79999 4.19998L2.99999 3.59998Z"
                    fill="#fff"
                  />
                </svg>
              </span>
              <span
                className="pl-5 d-lg-none"
                onClick={() => showDropDownMenu(item)}
              >
                <Image
                  src={require("../assets/image/icons/arrow-down-size-sm.svg")}
                  className="arrowdown-mobile"
                />
              </span>
            </>
          )}
        </div>

        {item.isSubmenu && (
          <ul className="sub-menu">
            {subMenu.map((el) => (
              <li key={el.id}>
                <a href={isFixedLink ? `https://innovation.com.vn${el.href}` : el.href}>
                  {TranslateLanguage("serviceSubMenu", el.label)}
                </a>
              </li>
            ))}
          </ul>
        )}
      </li>
    );
  });
};

const Header = ({ isHome, isDetailPage, news, pageName, sub, children }) => {
  return (
    pageName === "ResidentialRealEstate" ?
      <header
        className="detail-event"
        id="home"
      >
        <nav role="navigation" id="header">
          <Container className="height65">
            <div className="flex-header">
              <div className="content-header">
                <Logo />
                <HamburgerButton />

                <div
                  id="mainMenu"
                  className={`top_menu collapse ${isDetailPage ? "page-detail" : ""
                    }`}
                  aria-expanded="false"
                >
                  <ul className="nav navbar-nav">
                    <RenderMenu menu={menu} isDetailPage={isDetailPage} news={news} isFixedLink={true} />
                    <li>
                      <LanguageSelector />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Container>
          <div className="container h-100">
            <div
              className={`cls-banner-content ${isHome ? "align-items-stretch home" : ""
                }`}
            >
              {!isHome && (
                <>
                  <div className="title-banner-event">{TranslateLangSimpleWithContext(["ResidentialRealEstate", "title1"])}</div>
                  <div className="blue-label">
                    {TranslateLangSimpleWithContext(["ResidentialRealEstate", "title2"])}
                  </div>
                </>
              )}
              {isHome && (children)}
            </div>
            <div className="header-info-block">
              <div className="row-block row-block-1">
                <div className="col-6 col-block vet-c-line">
                  <div className="title">
                    {TranslateLangSimpleWithContext(["ResidentialRealEstate", "tDate"])}
                  </div>
                  <div className="content-1">
                    {TranslateLangSimpleWithContext(["ResidentialRealEstate", "cDate"])}
                  </div>
                </div>
                <div className="col-6 col-block">
                  <div className="title">
                    {TranslateLangSimpleWithContext(["ResidentialRealEstate", "tHour"])}
                  </div>
                  <div className="content-1">
                    {TranslateLangSimpleWithContext(["ResidentialRealEstate", "cHour"])}
                  </div>
                </div>
              </div>
              <div className="row-block row-block-2">
                <div className="title">
                  {TranslateLangSimpleWithContext(["ResidentialRealEstate", "tPlace"])}
                </div>
                <div className="content-1">
                  {TranslateLangSimpleWithContext(["ResidentialRealEstate", "cPlace"])}
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div className="flex-bg-left"></div>
        <div className="flex-bg-right"></div>
      </header>
      :
      <header
        className={`header-front-page ${isDetailPage ? "detail" : ""}`}
        id="home"
      >
        <nav role="navigation" id="header">
          <Container className="height65">
            <div className="flex-header">
              <div className="content-header">
                <Logo />
                <HamburgerButton />

                <div
                  id="mainMenu"
                  className={`top_menu collapse ${isDetailPage ? "page-detail" : ""
                    }`}
                  aria-expanded="false"
                >
                  <ul className="nav navbar-nav">
                    <RenderMenu menu={menu} isDetailPage={isDetailPage} news={news} />
                    <li>
                      <LanguageSelector />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </Container>
          <div className="container h-100">
            <div
              className={`cls-banner-content ${isHome ? "align-items-stretch home" : ""
                }`}
            >
              {!isHome && (
                <>
                  <div className="title-banner">{pageName}</div>
                  {sub && <div className="subtitle-banner">{sub}</div>}
                </>
              )}
              {isHome && (children)}
            </div>
          </div>
        </nav>
      </header>
  );
};
export default Header;