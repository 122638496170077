import React from "react";

import Header from "../Header";
import {
  ResidentialRealEstate,
  CoCreation,
  InspireInsight,
  AccelerationIdea,
  AccelerationChallenge,
  Trainning,
  AdvisoryStrategy,
  AdvisoryGotoMarket,
} from "./index";
import { TranslateLanguage } from "../../contexts/Languages";

export const DetailPage = ({ history }) => {

  const { location } = history;
  const { pathname } = location;
  let pageName = "";
  let subName = "";
  let color = "white";
  let component = {};

  switch (pathname) {
    case "/inspire-insight":
      // code block
      pageName = "INSPIRE & INSIGHT";
      component = <InspireInsight />;
      break;
    case "/idea-crowd-sourcing":
      // code block
      pageName = "ACCELERATION";
      subName = "Idea Crowd-Sourcing";
      component = <AccelerationIdea />;
      break;
    case "/challenge":
      // code block
      pageName = "ACCELERATION";
      subName = "Challenge";
      component = <AccelerationChallenge />;
      break;
    case "/trainning":
      // code block
      pageName = "Training";
      component = <Trainning />;
      break;
    case "/advisory-go-to-market":
      // code block
      pageName = "Advisory";
      subName = "Go to Market";
      component = <AdvisoryGotoMarket />;
      break;
    case "/advisory-strategy":
      // code block
      pageName = "Advisory";
      subName = "Strategy";
      component = <AdvisoryStrategy />;
      break;
    case "/co-creation":
      // code block
      pageName = "Co-Creation";
      color = "#EEF7FC";
      component = <CoCreation />;
      break;
    case "/ResidentialRealEstate":
      // code block
      pageName = "ResidentialRealEstate";
      component = <ResidentialRealEstate />;
      break;
    default:
    // code block
  }

  return (
    <React.Fragment>
      <Header
        isDetailPage={true}
        pageName={pageName === "ResidentialRealEstate" ? pageName : TranslateLanguage('homePage', pageName)}
        sub={TranslateLanguage('homePage', subName)}
      />
      <div
        style={{ backgroundColor: color }}
        className="flex justify-content-center page-detail"
      >
        {component}
      </div>
    </React.Fragment>
  );
};
