import React, { useContext } from "react";

import { Image, Container } from "react-bootstrap";
import { TranslateLanguage, LanguageContext } from "../../contexts/Languages";

export const AdvisoryGotoMarket = () => {
  const languageContext = useContext(LanguageContext);
  const { language } = languageContext;
  return (
    <div className="acceleration-idea d-flex align-items-center">
      <Container className="flex">
        <p className="description mb-3 text-center">
          {TranslateLanguage('goToMarketPage', 'After building our solutions, we need a plan to go to market. Our dedicated experts can support you through your investment decision-making process and advise you on a range of issues.', 'contentPage')}
        </p>
        <Image
          src={require(`../../assets/image/Advisory-Go-to-Market-${language.id}.svg`)}
          alt="acceleration-idea"
          className="img-fluid mt-4 d-none d-md-block"
        />
        <Image
          src={require(`../../assets/image/Advisory-Go to Market-mobile-${language.id}.svg`)}
          alt="acceleration-idea"
          className="img-fluid mt-4 d-md-none"
        />
      </Container>
    </div>
  );
};
