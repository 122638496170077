import React, { useContext, useEffect } from "react";
import { Container, Image } from "react-bootstrap";
import { TranslateLang, LanguageContext } from "./../../contexts/Languages";
import $ from "jquery";

export const Submit = () => {
  const languageContext = useContext(LanguageContext);
  const { language } = languageContext;
  useEffect(() => {
    document.title = "KPMG & TMA | Innovation Readiness Assessment";
    $("meta[name=description]").attr("content", "Take our free Innovation Readiness Assessment to have an initial view on your organization's innovation capability.");
    $("meta[property='og:title']").attr("content", "KPMG & TMA | Innovation Readiness Assessment");
    $("meta[property='og:description']").attr("content", "Take our free Innovation Readiness Assessment to have an initial view on your organization's innovation capability.");
    $("meta[property='og:image']").attr("content", "https://innovation.com.vn/linkedin_thumbnail.png");
    $("meta[property='og:url']").attr("content", "https://innovation.com.vn/assessment");
  }, [language]);
  return (
    <>
      <section className="assessment-submit">
        <Container className="height65">
          <div className="content-header">
            <div className="navbar-header logo mr-5">
              <Image className="mr-4" src={require("../../assets/image/kpmg-logo.svg")} alt="logo" />
              <Image src={require("../../assets/image/tma-logo.svg")} alt="logo" />
            </div>
          </div>
        </Container>
        <div className="container h-100">
          <div className="row h-101">
            <div className="col-lg-4 col-xl-5">
            </div>
            <div className="col-lg-8 col-xl-7 d-flex justify-content-center flex-column">
              <div className="mobile-margin">
                <div className="submit-container">
                  <p className="submit-cls-title">
                    {TranslateLang("submitPage.title")}
                  </p>
                  <p className="submit-text">
                    {TranslateLang("submitPage.desciption")}
                  </p>
                  <form
                    id="assessment-submit"
                  >
                    <div className="form-group">
                      <input
                        required
                        type="text"
                        name="full_name"
                        pattern="(?!^ +$)^.+$"
                        className="form-control"
                        aria-describedby="fullNameInput"
                        placeholder={TranslateLang("submitPage.fullname") + "*"}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        required
                        type="text"
                        name="job_title"
                        pattern="(?!^ +$)^.+$"
                        className="form-control"
                        aria-describedby="jobTitleInput"
                        placeholder={TranslateLang("submitPage.jobtitle") + "*"}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        required
                        type="text"
                        name="company_name"
                        pattern="(?!^ +$)^.+$"
                        className="form-control"
                        aria-describedby="companyInput"
                        placeholder={TranslateLang("submitPage.company") + "*"}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        required
                        type="email"
                        name="email"
                        pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$"
                        title="Your email is wrong format"
                        className="form-control"
                        aria-describedby="emailInput"
                        placeholder={TranslateLang("submitPage.email") + "*"}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        required
                        type="tel"
                        pattern="^ *\(? *\+?(\d[ ()]*){10,20} *$"
                        title="Your phone number is wrong format"
                        name="mobile"
                        className="form-control"
                        aria-describedby="phoneInput"
                        placeholder={TranslateLang("submitPage.mobile") + "*"}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        required
                        type="text"
                        name="industry"
                        pattern="(?!^ +$)^.+$"
                        className="form-control"
                        aria-describedby="industryInput"
                        placeholder={TranslateLang("submitPage.industry") + "*"}
                      />
                    </div>
                    <div className="form-group">
                      <select required name="company_size" id="company_size">
                        <option value="" hidden>{TranslateLang("submitPage.companyrevenuesize")}*</option>
                        <option value="0">&lt; 1,000 {TranslateLang("submitPage.billion")}</option>
                        <option value="1">{TranslateLang("submitPage.between")} 1,000 - 2,000 {TranslateLang("submitPage.billion")}</option>
                        <option value="2">{TranslateLang("submitPage.between")} 2,000 - 6,000 {TranslateLang("submitPage.billion")}</option>
                        <option value="3">{TranslateLang("submitPage.between")} 6,000 - 10,000 b{TranslateLang("submitPage.billion")}</option>
                        <option value="4">&gt; 10,000 {TranslateLang("submitPage.billion")}</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <select required name="number_employee" id="number_employee">
                        <option value="" hidden>{TranslateLang("submitPage.numberOfEmploymee")}*</option>
                        <option value="0">&lt; 100 {TranslateLang("submitPage.people")}</option>
                        <option value="1">{TranslateLang("submitPage.between")} 100 - 300 {TranslateLang("submitPage.people")}</option>
                        <option value="2">{TranslateLang("submitPage.between")} 500 - 700 {TranslateLang("submitPage.people")}</option>
                        <option value="3">&gt; 700 {TranslateLang("submitPage.people")}</option>
                      </select>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary submit-btn-gradient mt-3"
                    >
                      {TranslateLang("submitPage.takeAssessment")}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
