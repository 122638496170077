import React, { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import SelectableContext from "react-bootstrap/SelectableContext"; //https://github.com/react-bootstrap/react-bootstrap/issues/4176

import { languageOptions } from "../languages";

import { LanguageContext } from "../contexts/Languages";

export default function LanguageSelector() {
  const languageContext = useContext(LanguageContext);

  const handleLanguageChange = () => {
    let temp = languageContext.language.id;
    let selectedLanguage;

    if (temp === "en") {
      selectedLanguage = languageOptions.find(
        (item) => item.id === 'vn'
      );
    } else {
      selectedLanguage = languageOptions.find(
        (item) => item.id === 'en'
      );
    }

    console.log("onSelect ", temp);
    // set selected language by calling context method
    languageContext.setLanguage(selectedLanguage);

    window.history.pushState("", "", `?lang=${selectedLanguage.id}`);

    // set languageTo localStorage

    localStorage.setItem("innovation_lang", JSON.stringify(selectedLanguage));
  };

  const reverseLang = (text) => {
    return text === 'en' ? 'Tiếng Việt' : 'English'
  }

  return (
    // <SelectableContext.Provider value={false}>
    //   <Dropdown onSelect={handleLanguageChange}>
    //     <Dropdown.Toggle as="div" className=" pb-0 border-0">
    //       {languageContext.language.text}
    //     </Dropdown.Toggle>

    //     <Dropdown.Menu>
    //       {languageOptions.map((item) => (
    //         <Dropdown.Item eventKey={item.id} key={item.id}>{item.text}</Dropdown.Item>
    //       ))}
    //     </Dropdown.Menu>
    //   </Dropdown>
    // </SelectableContext.Provider>
    <div>
      <a href="javascript:void(0);" onClick={handleLanguageChange}>{reverseLang(languageContext.language.id)}</a>
    </div>
  );
}
