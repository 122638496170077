import React, { useContext } from "react";

import { Image, Container } from "react-bootstrap";
import { TranslateLanguage, LanguageContext } from "../../contexts/Languages";

export const AdvisoryStrategy = () => {
  const languageContext = useContext(LanguageContext);
  const { language } = languageContext;
  return (
    <div className="acceleration-idea d-flex align-items-center">
      <Container className="flex">
        <p className="description mb-3 text-center">
          {TranslateLanguage('strategyPage', 'The innovation strategy includes the assessment of innovation in context of the organization, the innovation vision, the innovation objectives, portfolio, the plans to achieve them, as well as the necessary organizational structure, support and processes.', 'contentPage')}
        </p>
        <Image
          src={require(`../../assets/image/Advisory-Strategy-${language.id}.svg`)}
          alt="acceleration-idea"
          className="img-fluid mt-4 d-none d-md-block"
        />
        <Image
          src={require(`../../assets/image/advisory-strategy-mobile-${language.id}.svg`)}
          alt="acceleration-idea"
          className="img-fluid mt-4 d-md-none"
        />
        <p className="description mb-3 text-center mt-4">
          {TranslateLanguage('strategyPage', 'The result is a multi-dimensional picture, which shows the client a full assesment of the innovation context, areas which need action, where competitors may be ahead and specific strengths that can be applied', 'contentBelow')}
          
        </p>
      </Container>
    </div>
  );
};
