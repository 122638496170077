const en = {
  common: {
    readMore: 'Read more',
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    messagePlaceholder: 'Tell us about your challenge',
    submit: 'Submit',
    submitSuccess: 'Your form was submitted successfully.',
    promiseSendEmail:'We will send you more information to your email.',
    returnHome: 'Return Home',
    thankYou: 'Thank you',
    footer: 'KPMG Tax and Advisory Limited & TMA Solutions.',
    viewLaunchingEvent: 'View Launching Event',
    cooperate: 'TMA Solutions and KPMG',
    downloadTitle: 'Click to download file',    
    launchingEventMetaTitle: 'Innovation as a Service is launched by TMA & KPMG Alliance',
    launchingEventMetaDescription: 'Innovation as a Service – launched by TMA & KPMG – helps companies to solve their challenges by applying the latest technologies to create innovative products and services, and to be more competitive.',
  },
  menu: {
    home: "Home",
    ecosystem: "Ecosystem",
    service: "Innovation as a service",
    whyChooseUs: "Why Choose Us",
    onlineAssessment: "Online Assessment",
    innovationCommunity: "Community",
    contactUs: "Contact Us",
  },
  serviceSubMenu: {
    inspireInsight: "Inspire & Insight",
    accelerationIdea: "Acceleration / Idea Crowd-Sourcing",
    accelerationChallenge: "Acceleration / Challenge",
    training: "Training",
    coCreation: "Co-Creation",
    advisoryStrategy: "Advisory / Strategy",
    advisoryGoToMarket: "Advisory / Go to Market",
  },
  homePage: {
    DetailEvent: "Accelerate Digital Transformation with Innovation as a service",
    homeBanner: {
      message1: 'Launching Innovation as a Service',
      message2: 'for the first time in Vietnam',
    },
    homeBanner2: {
      title: 'Are you ready to innovate? ',
      message1: 'Take our free Innovation Readiness Assessment to have an initial view on your organization\'s innovation capability.',
      button:'Free Self Assessment'
    },
    innovationEcosystem: {
      title: "Why Innovation as a Service?",
      organizeImg: require("../assets/image/innovation-ecosystem.svg"),
      content: {
        content1:
          "The KPMG & TMA Alliance will eliminate the complexity of innovation so you can do what you do best – generate new ideas for your business.",
        content2:
          "You don’t need to build an expensive in-house IT team to develop new business solutions and worry about keeping up with emerging technologies. You leave the hard work to us.",
        content3:
          "Our team has over 3000 professionals in Vietnam with skills across multiple fields including business, tax and legal. We have an extensive skill set in the technology field, particularly in areas such as blockchain, IoT, machine learning, artificial intelligence, cloud computing, big data, chatbot, enterprise applications (e-commerce, DMS, ERP, HRMS, CRM, business intelligence) and cybersecurity. Leveraging our knowledge and experience in these fields, we are confident to transform your innovative ideas to reality.",
      },
    },
    whyInnovation: {
      title: "Why Innovation as a Service?",
      des: "Transforming ideas into digital solutions",
      block1: {
        title:
          "How can KPMG & TMA Alliance help innovate and transform ideas into a solution for your organization?",
        content1: "Establish Innovation Committee within your organization",
        content2:
          "Facilitate innovation workshop to generate ideas (KPMG & TMA Alliance)",
        content3: "Co-develop a Proof of Concept - PoC (KPMG & TMA Alliance)",
        content4:
          "Pilot and evaluate results (Your organization together with KPMG & TMA Alliance)",
        content5:
          "Roll out (Your organization together with KPMG & TMA Alliance)",
      },
      block2: {
        title:
          "What kind of investment does your organization need to have in order to access Innovation as a Service?",
        content1:
          "Innovation Committee: It is recommended that an Innovation Committee is formed within your organization. This is at no cost to you.",
        content2:
          "Innovation as a Service: This varies from one idea to another. For a very affordable fee, you will have access to world class business consultants and technologists to help you generate ideas and turn them into digital solutions.",
        contactUs:
          "Contact us for more information to get started on your innovation process.",
      },
      block3: {
        title:
          "What are examples of business objectives or outcomes that an organization can expect from Innovation as a Service?",
        content1: "Increase revenue",
        content2: "Reduce operating cost",
        content3: "Optimize business solutions",
        content4: "Automate processes",
        content5: "Digitalize information",
        content6: "Resolve technical challenges",
        textDes:
          "We have the ability to transform ideas to market in as a little as 4 weeks",
      },
    },
    theInnovationLifecycle: {
      title: "The Innovation Lifecycle",
      textDes:
        "From a reputable alliance, clients will have the opportunity to leverage specialist skills and technologies with affordable cost at lighting speed",
      lifeCycleImg: require("../assets/image/our-approach.png"),
      lifeCycleImgMobile: require("../assets/image/our-approach-mobile.png"),
    },
    innovationAsAService: {
      title: "Innovation as a Service",
      ourApproach: "Our approach",
      inspireInsight: {
        title: "Inspire & Insight",
        blockContent1: {
          title: "Innovation Workshop",
          textDes:
            "Stimulating innovation by providing various workshops in different themes or styles by start-ups, experts and industry leaders",
        },
        blockContent2: {
          title: "Industry Roadshow",
          textDes:
            "Connecting leading corporations or relevant startups on certain themes or business requirements",
        },
      },
      training: {
        title: "Training",
        content: 'Deliver both bespoke and standardized training workshops to enhance innovation skill-sets in strategy, design thinking, technical knowledge and sense'
      },
      advisory: {
        title: 'Advisory',
        blockContent1: {
          title: "Strategy",
          textDes:
            "The innovation strategy includes assessments of innovation in context of the organization, the innovation vision, the innovation objectives, portfolio, the plans to achieve them, resources & budget, structure, culture & values and ecosystem",
        },
        blockContent2: {
          title: "Go to Market",
          textDes:
            "After building our solutions, we need a plan to go to market. Our dedicated experts can support you through your investment decision making process and advise you on a range of issues",
        },
      },
      coCreation: {
        title: 'Co-Creation',
        content: `<li>Product Planning</li>
        <li>Solution Consulting</li>
        <li>Prototyping</li>
        <li>Proof of Concept</li>
        <li>R&D</li>
        <li>UX/UI Design</li>
        <li>Product Development</li>
        <li>Product Testing</li>
        <li>Porting & Migration</li>
        <li>IT Managed Services</li>`
      },
      acceleration: {
        title: 'Acceleration',
        blockContent1: {
          title: "Idea Crowd-Sourcing",
          textDes:
            "Establishing online and offline internal idea screening & incubation mechanism",
        },
        blockContent2: {
          title: "Challenge",
          textDes:
            "Launching an open or closed challenge program tailored around a corporate’s business objective to pursue innovation-driven development (prioritization, strategy, case for change)",
        },
      },
      download: {
        textDes: 'You want to find the best matching Innovation Service for your business?',
        linkLabel: 'Download Innovation as a Service Package'
      }
    },
    whyChooseUs: {
      title: 'Why Choose Us',
      content1: 'We are your trusted Business & Technology partners with a combined of nearly 50 years of experience helping clients to innovate and transform.',
      content2: 'We provide end-to-end services from Business to Consulting to Digital Technology; we can help you address any business challenges using technology.',
      content3: 'We have deep technology capabilities, ranging from mainstream enterprise technology to the new and emerging digital Industry 4.0 technology.',
      content4: 'We provide an affordable but world class innovative framework that can get an innovation journey started immediately and can bring your ideas to market in as little as 4 weeks.',
    },
    getInTouch: {
      title: 'Get in Touch with Us',
      textDes: 'Please let us know what we can support on your Innovation journey. We will respond to you as soon as possible.',
      company: 'KPMG Tax and Advisory Limited',
      location: '10th Floor, Sunwah Tower, 115 Nguyen Hue Street, District 1, Ho Chi Minh City, Viet Nam',
      tel: '+84 (28) 3821 9266'
    }
  },
  accelerationIdeaPage: {
    ideaCrowdSourcingImg: require("../assets/image/acceleration-idea-en.svg"),
  },
  launchingEvent: {
    headLine: 'TMA Solutions and KPMG',
    title: 'Together we launch Innovation as a Service first time in Vietnam',
    paragraph1: `We are in the post COVID period when the market is changing and only innovating companies can survive and succeed. Understanding the enterprises’ challenges, the KPMG & TMA Alliance has  launched Innovation as a Service, which helps companies to solve their challenges by applying the latest technologies to create innovative products and services, and to be more competitive. `,
    paragraph2: 'TMA has opportunities to involve in global innovation with leading tech companies that help TMA learning not only the latest technologies, especially the industry 4.0 technologies, but also the innovation process from ideas to prototype to product development and deployment. We want to apply these technologies and best practices to support Vietnamese companies in innovation by applying the latest tech and methods to build creative products and solutions to increase the competitive advantages for customers in various industries.',
    paragraph3: `Meanwhile, KPMG Vietnam over the past 26 years has developed into the market leader in the field of business & technology consulting firm. Through combining multiple approaches with deep and practical industry knowledge, KPMG has completed over 20 technology transformation projects within the last 36 months.`,
    paragraph4: `Innovation as a Service operates based on the co-creation among 3 teams (Client - KPMG - TMA) that work together to analyze challenges, propose innovation solutions to develop prototypes and products. This model also helps building an innovation team for clients to make innovation become part of corporate culture and practice. `,
    paragraph5: `To support the innovation process from ideas to product, TMA and KPMG have developed a Hackathon platform. This platform enables companies to gather ideas from community, to launch and operate challenges and hackathons on-line with minimum effort and cost. This platform also cultivates an innovation ecosystem, a network of innovative insights and ideas.`,
    paragraph6: `Both TMA and KPMG are sharing the same vision of bringing global innovation practices to Vietnam. By combining KPMG consulting and domain knowledge with TMA’s technology, we have a very strong capability to offer Innovation as a Service in Vietnam enterprises.`,
    paragraph7: '',
    paragraph8: '',
    chairman1Img: require('../assets/image/chairman-kpmg.png'),
    chairman2Img: require('../assets/image/chairman-tmasolution.png'),
    exploreMore: 'Explore more:',
  },
  submitPage:{
    title: 'Start your Innovation Readiness Assessment',
    desciption: 'Get an initial assessment of the topic of innovation capability and measure yourself against the average of numerous companies worldwide. In just a few minutes we will create your customized factsheet.',
    fullname:'Full name',
    jobtitle:'Job title',
    company:'Company',
    email:'Email',
    mobile:'Mobile',
    industry:'Industry',
    companyrevenuesize:'Company revenue size',
    billion:'bil VND',
    numberOfEmploymee: 'Number of employee',
    people:'',
    between:'between',
    takeAssessment: 'Take free assessment'
  },
  questionnairePage:{
    commentLabel: 'Please share what topic of discussions, workshops about Digital Innovation that you are interested in:',
    viewresult: "View Your Result",
    questionsAnswered: "questions answered",
    tellYourThinking: "Tell us about your thinking"
  },
  resultPage: {
    pageTitle: "Your Result",
    thankful1: "THANK YOU FOR TAKING THE TIME TO COMPLETE THE INNOVATION READINESS ASSESSMENT.",
    thankful2: "We hope that you and your organization can start your Innovation journey successfully and optimizing your abilities with targeted consulting by KPMG!",
    download: "Download The CIO Survey Report 2020",
    sponsored: "Sponsored by KPMG & TMA Innovation Alliance",
    contact: "For more information, please contact us:"
  },
  ResidentialRealEstate: {
    title1: "Digital Innovation, Potential Unleash",
    title2: "RESIDENTIAL REAL ESTATE",
    title2: "Residential Real Estate",
    tDate: "Date",
    tHour: "Time",
    tPlace: "Venue",
    cDate: "25 February 2021",
    cHour: "10:00 - 11:30",
    cPlace: "Sky Lounge, Level 22, Hotel des Arts Saigon MGallery Collection 78 Nguyen Thi Minh Khai, District 3, HCMC",
    block2: {
      title: "Our specialized workshop will cover",
      text1: "The impact of Digital Innovation on business",
      text2: "Panel Discussion: How industry leaders stay ahead by digital innovation",
      text3: "The right approach to INNOVATION",
    },
    block3: {
      text1: "Should you have any further concerns/special inquiries, please let us know at",
      contact1: "Ms. Nguyen Thanh Truc",
      contact2: "Ms. Bui Thi Ngoc Oanh",
    },
    block5: {
      text1: "This event is reserved for selected guests and we look forward to welcoming you to our event!",
      text2: "Residential Real Estate: Digital Innovation, Potential Unleash"
    },
    block6: {
      title: "Exclusive Invitation",
      text1: "The land scarcity makes the competition in the real estate sector more and more intense. To increase competitiveness, whether the industry companies only keep up with their current business activities. Or they need to seek for a disruptive way applying the latest technologies to build break-through vision:",
      button1: "Create new types of assets",
      button2: "Expand the ecosystem",
    },
    block7: {
      title: "Speakers"
    },
    block8: {
      title: "Format",
      text1: "Focus group for C-level invited only",
      text2: "The group event is the 1st Episode in the “Inno+ series 2021” followed with the 2nd Episode of Exclusive on-site Follow-up Consulting tailor-made for your own business.",
    }
  }
};

export default en;
