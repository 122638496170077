import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";

import { LINE_IMAGE, WHITE_LINE_IMAGE } from "../../common/image";
import { TranslateLanguage } from "../../contexts/Languages";

const firstSection = [
  {
    id: 1,
    icon: require("../../assets/image/icons/innovation-icon.svg"),
    title: "Innovation Workshop",
    description: `Innovation workshops are designed to promote employees' innovation mindset, cultivate an internal innovative culture and practice; and enhance their ability to implement innovation strategies more effectively.`,
  },
  {
    id: 2,
    icon: require("../../assets/image/icons/industry-roadshow.svg"),
    title: "Industry Roadshow",
    description: 'An intensive, curated experience with transformative startups, innovative companies and leading corporations to achieve your growth, investment, innovation objectives.',
  },
];

const technologyTrends = {
  techTrendTitle: "Our Focus",
  techTrendDescription: "Explore industry trends and innovation",
  techTrendContent: [
    [
      {
        id: 1,
        icon: require("../../assets/image/icons/artificial-intelligence.svg"),
        title: "Artificial intelligence",
      },
      {
        id: 2,
        icon: require("../../assets/image/icons/internet-of-things.svg"),
        title: "Internet of things",
      },
      {
        id: 3,
        icon: require("../../assets/image/icons/big-data.svg"),
        title: "Big data",
      },
      {
        id: 4,
        icon: require("../../assets/image/icons/robotics.svg"),
        title: "Robotics",
      },
    ],
    [
      {
        id: 5,
        icon: require("../../assets/image/icons/cloud-computing.svg"),
        title: "Cloud computing",
      },
      {
        id: 6,
        icon: require("../../assets/image/icons/blockchain.svg"),
        title: "Blockchain",
      },
      {
        id: 7,
        icon: require("../../assets/image/icons/automotive.svg"),
        title: "Automotive",
      },
      {
        id: 8,
        icon: require("../../assets/image/icons/fintech.svg"),
        title: "Fintech",
      },
    ],
    [
      {
        id: 9,
        icon: require("../../assets/image/icons/retail.svg"),
        title: "Retail",
      },
      {
        id: 10,
        icon: require("../../assets/image/icons/real-estate.svg"),
        title: "Real estate",
      },
      {
        id: 11,
        icon: require("../../assets/image/icons/healthcare.svg"),
        title: "Healthcare",
      },
    ],
  ],
};

const delivery = {
  deliveryTitle: "Our Delivery",
  deliveryDes:
    "Establish connections across an innovative external network and promote innovation",
  deliveryContent: [
    {
      id: 1,
      icon: require("../../assets/image/icons/startup-story.svg"),
      title: "Startup Story",
    },
    {
      id: 2,
      icon: require("../../assets/image/icons/technology-insights.svg"),
      title: "Technology Insights",
    },
    {
      id: 3,
      icon: require("../../assets/image/icons/market-insights.svg"),
      title: "Market Insights",
    },
    {
      id: 4,
      icon: require("../../assets/image/icons/newways-of-working.svg"),
      title: "New ways of working",
    },
  ],
};


export const InspireInsight = () => {
  const {
    techTrendTitle,
    techTrendDescription,
    techTrendContent,
  } = technologyTrends;

  const {
    deliveryTitle,
    deliveryDes,
    deliveryContent,
  } = delivery

  const sectionTitle = (title, des, isWhite) => {
    let className = isWhite ? 'text-white' : ''
    return (
      <div className="justify-content-center">
        <div className="text-center flex">
          <div className={`title-block mb-1 ${className}`}>{TranslateLanguage('inspireInsightPage', title)}</div>
          <Image src={isWhite ? WHITE_LINE_IMAGE : LINE_IMAGE} alt="line icon" />
          <p className={`mt-3 section-des ${className}`}>{TranslateLanguage('inspireInsightPage', des)}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="inspire-insight">
      <Container>        
        <div className="first-section">
          <Row>
            {firstSection.map((el, idx) => (
              <Col md={6} key={el.id}>
                <div className={`box-wrapper text-center ${idx < firstSection.length - 1 ? 'mb-4 mb-lg-0' : ''}`}>
                  <Image src={el.icon} alt={el.title} className="mb-1 img-fluid"/>
                  <div className="title fs22 bold mt-2">{TranslateLanguage('inspireInsightPage',el.title)}</div>
                  <div className="description mt-3">{TranslateLanguage('inspireInsightPage',el.description)}</div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
        <div className="border-bottom-block" />
        <div className="we-focus-on">
          {sectionTitle(techTrendTitle, techTrendDescription)}

          {techTrendContent.map((row, index) => (
            <Row className="no-gutters" key={index}>
              {row.map((item) => (
                <Col xs={6} lg={3} key={item.id}>
                  <div className="text-center p-4 box">
                    <div className="icon">
                      <Image src={item.icon} alt={item.title}  className="img-fluid"/>
                    </div>
                    <div className="title fs18 mt-4 bold">{ TranslateLanguage('inspireInsightPage',item.title)}</div>
                  </div>
                </Col>
              ))}
            </Row>
          ))}
        </div>
      </Container>

      <div className="delivery">
        <Container>
          {sectionTitle(deliveryTitle, deliveryDes, true)}
          <Row>
            {deliveryContent.map(item => (
              <Col xs={6} lg={3} key={item.id}>
                <div className="text-center">
                  <Image src={item.icon} alt={item.title} className="img-fluid"/>
                  <div className="title fs20 text-white">{TranslateLanguage('inspireInsightPage',item.title)}</div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
  );
};
