import React, { useContext } from "react";
import { Container, Image } from "react-bootstrap";
import { languageOptions } from "../../languages";
import { TranslateLangSimple } from "./../../contexts/Languages";

const QUESTION_YES_NO = 1;
const QUESTION_LIST = 2;
const QUESTION_MULTIPLE = 3;
const URL_PAGE_CHECK = "/assessment";
const URL_PAGE_RESULT = "/result";

const existLang = localStorage.getItem('innovation_lang') ? JSON.parse(localStorage.getItem('innovation_lang')) : languageOptions[0];

const en_list_quetion = [
	{ text: "Do you have an innovation strategy?", type: QUESTION_YES_NO },
	{ text: "What are your innovation goals?", type: QUESTION_LIST },
	{ text: "What are the factors that determine your Innovation fostering program?", type: QUESTION_LIST },
	{ text: "Does your organization see challenges as opportunities?", type: QUESTION_YES_NO },
	{ text: "What is the level of uncertainty that your organization is willing to accept?", type: QUESTION_LIST },
	{ text: "How does your organization identify different innovation types?", type: QUESTION_LIST },
	{ text: "Who is responsible for innovation in your organization?", type: QUESTION_LIST },
	{ text: "Who is responsible for building innovative ideas in your business?", type: QUESTION_LIST },
	{ text: "Who are more important for your organization?", type: QUESTION_LIST },
	{ text: "How to gather innovative ideas from employees?", type: QUESTION_LIST },
	{ text: "Do you have an idea evaluation process?", type: QUESTION_LIST },
	{ text: "Do you understand the innovation journey from ideas to go-to-market?", type: QUESTION_YES_NO },
	{ text: "Do you have a strategy & roadmap for Innovation?", type: QUESTION_YES_NO },
	{ text: "Do you encourage innovation in the workplace? ", type: QUESTION_YES_NO },
	{ text: "Do you have innovative areas or innovative virtual tools?", type: QUESTION_YES_NO },
	{ text: "Do you have efficiency KPIs for Innovation?", type: QUESTION_YES_NO },
	{ text: "Does your organization create time for innovation?", type: QUESTION_YES_NO },
	{ text: "Are there organizational supports for innovation?", type: QUESTION_YES_NO },
	{ text: "What are your Innovation metrics?", type: QUESTION_LIST },
	{ text: "Would you like KPMG to contact you and have free workshop about Innovation and potentially conduct an Innovation Maturity Assessment for your company?", type: QUESTION_YES_NO }
]
const vn_list_quetion = [
	{ text: "Doanh nghiệp bạn có xây dựng chiến lược Đổi mới sáng tạo không?", type: QUESTION_LIST },
	{ text: "Mục tiêu Đổi mới sáng tạo của doanh nghiệp bạn là:", type: QUESTION_LIST },
	{ text: "Điều gì thúc đẩy sự đổi mới trong doanh nghiệp của bạn?", type: QUESTION_LIST },
	{ text: "Doanh nghiệp bạn có đón nhận các thử thách như là cơ hội để đổi mới?", type: QUESTION_LIST },
	{ text: "Mức dộ chấp nhận rủi ro của doanh nghiệp bạn?", type: QUESTION_LIST },
	{ text: "Làm thế nào để doanh nghiệp bạn xác định mô hình Đổi mới sáng tạo phù hợp với từng sản phẩm/dịch vụ?", type: QUESTION_LIST },
	{ text: "Ai chịu trách nhiệm thực hiện Đổi mới sáng tạo trong doanh nghiệp bạn?", type: QUESTION_LIST },
	{ text: "Ai chịu trách nhiệm xây dựng ý tưởng sáng tạo trong doanh nghiệp bạn?", type: QUESTION_LIST },
	{ text: "Ai quan trọng hơn trong nguồn tài nguyên nhân lực của doanh nghiệp bạn?", type: QUESTION_LIST },
	{ text: "Doanh nghiệp bạn thu thập ý tưởng nội bộ như thế nào?", type: QUESTION_LIST },
	{ text: "Căn cứ để doanh nghiệp bạn sàng lọc ý tưởng?", type: QUESTION_LIST },
	{ text: "Doanh nghiệp bạn đã hiểu rõ quá trình Đổi mới sáng tạo, từ tìm-kiếm-ý-tưởng đến đưa sản phẩm/ dịch vụ mới ra thị trường hay chưa?", type: QUESTION_LIST },
	{ text: "Doanh nghiệp bạn có lộ trình triển khai Đổi mới sáng tạo?", type: QUESTION_LIST },
	{ text: "Doanh nghiệp bạn có khuyến khích Đổi mới sáng tạo tại nơi làm việc?", type: QUESTION_LIST },
	{ text: "Doanh nghiệp bạn có các khu chức năng hoặc công cụ trực tuyến để phục vụ hoạt động Đổi mới sáng tạo?", type: QUESTION_LIST },
	{ text: "Doanh nghiệp bạn có chú trọng đảm bảo hiệu suất hơn thử nghiệm khả năng Đổi mới sáng tạo?", type: QUESTION_LIST },
	{ text: "Doanh nghiệp bạn có dành thời gian cho nhân viên thực hành Đổi mới sáng tạo?", type: QUESTION_LIST },
	{ text: "Doanh nghiệp bạn có các hỗ trợ quy mô toàn công ty cho hoạt động Đổi mới sáng tạo?", type: QUESTION_LIST },
	{ text: "Thước đo Đổi mới sáng tạo trong doanh nghiệp bạn là gì?", type: QUESTION_LIST },
	{ text: "Bạn có muốn KPMG liên hệ và cập nhật về Phương pháp đánh giá mức độ đổi mới sáng tạo của doanh nghiệp và các hội thảo ngành có liên quan? ", type: QUESTION_LIST }
]
const list_question = existLang.id == 'en' ? en_list_quetion : vn_list_quetion;
const en_list_anwser = [
	{ text: "Yes", point: 1 },
	{ text: "No", point: -1 },
	{ text: "Survival", point: -1 },
	{ text: "Organic growth", point: 1 },
	{ text: "Expanding with new products and services ", point: 2 },
	{ text: "Sustaining competitive advantage by actively seeking disruption ", point: 3 },
	{ text: "Keeping up with competitors ", point: -1 },
	{ text: "Customer feedback, surveys, and focus groups", point: 1 },
	{ text: "Employees and partners ", point: 2 },
	{ text: "Customer experience strategy", point: 3 },
	{ text: "Yes", point: 1 },
	{ text: "No", point: -1 },
	{ text: "None", point: -1 },
	{ text: "Moderate", point: 1 },
	{ text: "Risk-taking is encouraged", point: 2 },
	{ text: "Risks are actively sought out", point: 3 },
	{ text: "By the maturity of the market or other factors", point: 1 },
	{ text: "No formal methods", point: -1 },
	{ text: "Individuals", point: -1 },
	{ text: "R&D teams", point: 1 },
	{ text: "Groups who create ideas", point: 2 },
	{ text: "Top Management who manage the specified innovation function", point: 3 },
	{ text: "No one", point: -1 },
	{ text: "Specialized Innovation team ideates and implements and then turns over to the field  ", point: 1 },
	{ text: "Specialized Innovation team comes up with the ideas and field personnel implement them", point: 2 },
	{ text: "A joint multi-disciplinary team consisting of subject experts and field personnel ", point: 3 },
	{ text: "Highly specialized experts ", point: 1 },
	{ text: "Professionals who are \"a few feet deep but a mile wide\"", point: 2 },
	{ text: "No formal methods", point: -2 },
	{ text: "Suggestion box", point: -1 },
	{ text: "An open and transparent ideas portal", point: 2 },
	{ text: "ROI", point: -1 },
	{ text: "Gut feeling", point: 1 },
	{ text: "Game-changing potential of the idea", point: 2 },
	{ text: "Yes", point: 1 },
	{ text: "No", point: -1 },
	{ text: "Yes", point: 1 },
	{ text: "No", point: -1 },
	{ text: "Yes", point: 1 },
	{ text: "No", point: -1 },
	{ text: "Yes", point: 1 },
	{ text: "No", point: -1 },
	{ text: "Yes", point: -2 },
	{ text: "No", point: 2 },
	{ text: "Yes", point: 2 },
	{ text: "No", point: 0 },
	{ text: "Yes", point: 2 },
	{ text: "No", point: -2 },
	{ text: "No metrics", point: -2 },
	{ text: "One-dimensional metrics such as ROI and R&D budget  ", point: -1 },
	{ text: "Metrics based on idea generation and execution ", point: 1 },
	{ text: "Percent of sales from new products and services ", point: 2 },
	{ text: "Yes", point: 0 },
	{ text: "No", point: 0 }
]
const vn_list_anwser = [
	{ text: "Có", point: 1 },
	{ text: "Không", point: -1 },
	{ text: "Vì sự sống còn của doanh nghiệp", point: -1 },
	{ text: "Tăng trưởng tự thân từ việc tăng sản lượng và doanh thu bán hàng", point: 1 },
	{ text: "Mở rộng ra các sản phẩm, dịch vụ mới", point: 2 },
	{ text: "Không ngừng đột phá để duy trì lợi thế cạnh tranh", point: 3 },
	{ text: "Giữ vững vị thế trước đối thủ", point: -1 },
	{ text: "Phản hồi của khách hàng, thông tin thị trường từ hoạt động phỏng vấn, khảo sát", point: 1 },
	{ text: "Nhân viên và đối tác", point: 2 },
	{ text: "Chiến lược nâng cao trải nghiệm khách hàng", point: 3 },
	{ text: "Có ", point: 1 },
	{ text: "Không", point: -1 },
	{ text: "Thấp", point: -1 },
	{ text: "Trung bình", point: 1 },
	{ text: "Khuyến khích chấp nhận rủi ro trong việc sáng tạo", point: 2 },
	{ text: "Rủi ro được chấp nhận và chủ động ứng phó", point: 3 },
	{ text: "Thông qua mức độ phát triển của ngành hoặc các yếu tố khác ", point: 1 },
	{ text: "Không có phương thức cụ thể", point: -1 },
	{ text: "Cá nhân", point: -1 },
	{ text: "Nhóm R&D", point: 1 },
	{ text: "Nhóm nghĩ ra ý tưởng", point: 2 },
	{ text: "Quản lý cấp cao bộ phận Innovation ", point: 3 },
	{ text: "Không có", point: -1 },
	{ text: "Đội ngũ Innovation chuyên biệt; đảm nhận từ việc xây dựng ý tưởng, phát triển đến đưa giải pháp vào sử dụng.", point: 1 },
	{ text: "Đội ngũ Innovation chuyên biệt đảm nhận việc xây dựng ý tưởng, phát triển. Chuyên viên kinh doanh đảm nhận việc đưa giải pháp vào sử dụng.", point: 2 },
	{ text: "Nhóm đa lĩnh vực bao gồm các chuyên viên ở mọi lĩnh vực, phòng ban.", point: 3 },
	{ text: "Chuyên gia giàu kinh nghiệm", point: 1 },
	{ text: "Chuyên viên với nền kiến thức rộng", point: 2 },
	{ text: "Không có phương thức cụ thể", point: -2 },
	{ text: "Hộp thư góp ý", point: -1 },
	{ text: "Một hệ thống mở và minh bạch để tiếp nhận ý tưởng", point: 2 },
	{ text: "ROI - Chỉ số doanh thu trên chi phí", point: -1 },
	{ text: "Trực giác", point: 1 },
	{ text: "Tiềm năng thay đổi thị trường/doanh nghiệp của ý tưởng", point: 2 },
	{ text: "Có ", point: 1 },
	{ text: "Không", point: -1 },
	{ text: "Có ", point: 1 },
	{ text: "Không", point: -1 },
	{ text: "Có ", point: 1 },
	{ text: "Không", point: -1 },
	{ text: "Có ", point: 1 },
	{ text: "Không", point: -1 },
	{ text: "Có ", point: -2 },
	{ text: "Không", point: 2 },
	{ text: "Có ", point: 2 },
	{ text: "Không", point: 0 },
	{ text: "Có ", point: 2 },
	{ text: "Không", point: -2 },
	{ text: "Không có", point: -2 },
	{ text: "Thước đo một chiều như ROI hoặc ngân sách R&D", point: -1 },
	{ text: "Quá trình Quản lý ý tưởng và kết quả đầu ra", point: 1 },
	{ text: "Doanh thu từ sản phẩm, dịch vụ mới", point: 2 },
	{ text: "Có", point: 0 },
	{ text: "Không", point: 0 },

];
const list_anwser = existLang.id == 'en' ? en_list_anwser : vn_list_anwser;
const list_ans_ques = [
	[0, 1],
	[2, 3, 4, 5],
	[6, 7, 8, 9],
	[10, 11],
	[12, 13, 14, 15],
	[16, 17],
	[18, 19, 20, 21],
	[22, 23, 24, 25],
	[26, 27],
	[28, 29, 30],
	[31, 32, 33],
	[34, 35],
	[36, 37],
	[38, 39],
	[40, 41],
	[42, 43],
	[44, 45],
	[46, 47],
	[48, 49, 50, 51],
	[52, 53]
];
const data = {
	full_name: localStorage.getItem("assessment_full_name"),
	job_title: localStorage.getItem("assessment_job_title"),
	company_name: localStorage.getItem("assessment_company_name"),
	email: localStorage.getItem("assessment_email"),
	mobile: localStorage.getItem("assessment_mobile"),
	industry: localStorage.getItem("assessment_industry"),
	company_size: localStorage.getItem("assessment_company_size"),
	number_employee: localStorage.getItem("assessment_number_employee"),
	questions: [],
	comment: ""
};
export class PageQuestionnaire extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			total: 0,
			numbers: []
		};
		this.data = data;
		if (!this.data.full_name || !this.data.job_title || !this.data.company_name || !this.data.email
			|| !this.data.mobile || !this.data.industry || !this.data.company_size || !this.data.number_employee) {
			window.location = URL_PAGE_CHECK;
		}
	}
	onAnswer(iQuestion, iAnswer) {
		if (list_question[iQuestion].type == QUESTION_MULTIPLE && this.data.questions[iQuestion]) {
			this.data.questions[iQuestion] = {
				question_id: iQuestion + 1,
				answer_id: this.data.questions[iQuestion].answer_id.indexOf(iAnswer + 1) == -1
					? this.data.questions[iQuestion].answer_id.concat(iAnswer + 1)
					: this.data.questions[iQuestion].answer_id.filter(answer_id => answer_id != iAnswer + 1)
			};
		} else {
			this.data.questions[iQuestion] = {
				question_id: iQuestion + 1,
				answer_id: [iAnswer + 1]
			};
		}
		this.state.numbers[iQuestion] = !!this.data.questions[iQuestion].answer_id.length;
		this.state.total = this.state.numbers.filter(number => number).length;
		this.setState(this.state);
	}
	onSubmit() {
		if (this.state.total == list_ans_ques.length) {
			this.state.loading = true;
			this.setState(this.state);
			this.data.comment = this.refs.txtComment.value;
			const sum_point = this.data.questions.reduce((total, question) => total +
				question.answer_id.reduce((subTotal, answer_id) => subTotal + list_anwser[answer_id - 1].point, 0), 0);
			const formData = new FormData();
			formData.append("data", JSON.stringify(this.data));
			const requestOptions = {
				method: 'POST',
				body: formData
			};
			fetch('https://innovation.com.vn:8123/api/saveInfo', requestOptions)
				.then(async response => {
					const data = await response.json();
					// response_code : 0 is successful
					if (data.response_code == 0) {
						localStorage.removeItem("assessment_full_name");
						localStorage.removeItem("assessment_job_title");
						localStorage.removeItem("assessment_company_name");
						localStorage.removeItem("assessment_email");
						localStorage.removeItem("assessment_mobile");
						localStorage.removeItem("assessment_industry");
						localStorage.removeItem("assessment_company_size");
						localStorage.removeItem("assessment_number_employee");
						localStorage.setItem("assessment_sum_point", sum_point);
						window.location.href = URL_PAGE_RESULT;
					} else {
						alert('There was an network issue! Please try again.');
						this.state.loading = false;
						this.setState(this.state);
					}
				})
				.catch(error => {
					console.error('There was an error!', error);
					alert('There was an network issue! Please try again.');
					this.state.loading = false;
					this.setState(this.state);
				});
		} else {
			alert("Please answer the questions below");
			list_ans_ques.some((ans_ques, iQuestion) => {
				if (!this.data.questions[iQuestion] || !this.data.questions[iQuestion].answer_id.length) {
					this.refs["item" + iQuestion].scrollIntoView();
					return true;
				}
			});
		}
	}
	render() {
		return (
			<>
				<header className="header-front-page detail questionnaire-header">
					<nav role="navigation" id="header">
						<Container className="height65">
							<div className="content-header">
								<div className="navbar-header logo mr-5">
									<Image className="mr-4" src={require("../../assets/image/kpmg-logo.svg")} alt="logo" />
									<Image src={require("../../assets/image/tma-logo.svg")} alt="logo" />
								</div>
							</div>
						</Container>
						<div className="container h-100">
							<div className="cls-banner-content">
								<div className="title-banner">INNOVATION READINESS<br />ASSESSMENT</div>
							</div>
						</div>
					</nav>
				</header>
				<div className="flex justify-content-center page-detail">
					{
						this.state.loading
							? (
								<div className="questionnaire-loading flex">
									<div className="icon-loading"></div>
									<div>Result Calculation</div>
								</div>
							)
							: (
								<div className="questionnaire w-100">
									<Container className="flex flex-column">
										{
											list_ans_ques.map((ans_ques, iQuestion) => (
												<div ref={"item" + iQuestion} key={iQuestion} className="item w-100">
													<div className="header flex flex-row justify-content-start">
														<div className={"number flex" + (this.state.numbers[iQuestion] ? " checked" : "")}>
															<Image className="icon" src={require("../../assets/image/icons/questionnaire-question-radio.svg")} />
															<Image className="icon-checked" src={require("../../assets/image/icons/questionnaire-question-radio-checked.svg")} />
															<div>{iQuestion + 1}</div>
														</div>
														<div className="question">{list_question[iQuestion].text}</div>
													</div>
													<div className="body">
														{
															ans_ques.map(iAnswer => (
																<label key={iAnswer} className={list_question[iQuestion].type == QUESTION_YES_NO ? "answer-yes-no" : "answer"}>
																	<input checked={this.data.questions[iQuestion] && this.data.questions[iQuestion].answer_id.indexOf(iAnswer + 1) != -1} onClick={() => this.onAnswer(iQuestion, iAnswer)} name={"question-" + (iQuestion + 1)} type={list_question[iQuestion].type == QUESTION_MULTIPLE ? "checkbox" : "radio"} />
																	<Image className="icon" src={require("../../assets/image/icons/questionnaire-answer-" + (list_question[iQuestion].type == QUESTION_MULTIPLE ? "checkbox" : "radio") + ".svg")} />
																	<Image className="icon-checked" src={require("../../assets/image/icons/questionnaire-answer-" + (list_question[iQuestion].type == QUESTION_MULTIPLE ? "checkbox" : "radio") + "-checked.svg")} />
																	<div>{list_anwser[iAnswer].text}</div>
																</label>
															))
														}
													</div>
												</div>
											))
										}
									</Container>
									<Container className="flex flex-column">
										<div className="comment w-100">
											<div className="form-group">
												<div className="title">{TranslateLangSimple(["questionnairePage", "commentLabel"])}</div>
											</div>
											<div className="form-group">
												<textarea ref="txtComment" className="form-control" placeholder={TranslateLangSimple(["questionnairePage", "tellYourThinking"])}>{this.data.comment}</textarea>
											</div>
										</div>
									</Container>
									<div className="form w-100">
										<Container className="flex flex-row justify-content-between">
											<div className="message"><span className="count">{this.state.total}</span>/{list_ans_ques.length} <br />{TranslateLangSimple(["questionnairePage", "questionsAnswered"])}</div>
											<div onClick={() => this.onSubmit()} className="btn-submit flex">{TranslateLangSimple(["questionnairePage", "viewresult"])}</div>
										</Container>
									</div>
								</div>
							)
					}
				</div>
			</>
		);
	}
};
