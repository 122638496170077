import en from './en';
import vn from './vn';

export const dictionaryList = {
  en,
  vn,
};

export const languageOptions = [
  { id: 'en', text: 'EN'},
  { id: 'vn', text: 'VI' },  
];
